import { Title } from "components/Title";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { VersionCard } from "./VersionCard";

import { EditContext } from "../EditContext";

export default function VersionFlyout(props) {
  const { show, versions } = props;
  const context = useContext(EditContext);

  return (
    <Container show={show}>
      <Title alignTitle={"flex-start"} spanWidth="140px">
        Version History
      </Title>
      <Text>Current Version</Text>
      <VersionCard date={context.date} />
      <Text>Previous Versions</Text>
      <Content>
        {versions.map((version) => {
          return <VersionCard key={version.date} date={version.date} />;
        })}
      </Content>
    </Container>
  );
}

const Text = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  width: 100%;
  text-align: left;
  margin: 20px 0px 15px 0px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Container = styled.div`
  position: fixed;
  padding: 10px 20px 10px 20px;
  box-sizing: border-box;
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  width: 310px;
  right: 0;
  height: 100%;
  z-index: 2;
  box-shadow: -1px 0px 2px 0px lightgray;
`;

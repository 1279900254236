import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { palette } from "./palette";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import {
  genAmendedGraphDataset,
  genGraphDataset,
  getAffectedPeriods,
} from "./utils";

const CustomDot = (props) => {
  const { cx, cy, stroke, amendedMetadata, period, periods } = props;
  const { amended, date } = props.payload;

  if (amended) {
    const amendedGraph = genAmendedGraphDataset(
      amendedMetadata[date].weeks,
      periods
    );
    const affectedPeriods = getAffectedPeriods(amendedGraph, periods);

    if (affectedPeriods.includes(period)) {
      return (
        <svg
          x={cx - 6}
          y={cy - 11}
          width="15"
          height="24"
          viewBox="0 0 15 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 10.5L6.5 18L2 10.5L6.5 3L11 10.5Z"
            fill="black"
            stroke="white"
            strokeWidth="3"
          />
        </svg>
      );
    }
  }

  return (
    <svg
      width="10"
      height="10"
      x={cx - 5}
      y={cy - 5}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5" cy="5" r="5" fill="none" />
    </svg>
  );
};

export function Graph(props) {
  const { periods, weeks, amendedMetadata } = props;
  const [graphDataset, setGraphDataset] = useState([]);

  useEffect(() => {
    const graphDataset = genGraphDataset(weeks, periods);
    setGraphDataset(graphDataset);
  }, [periods, weeks]);

  function genGraphMargin() {
    if (window.innerWidth < 400) {
      return { left: 2, right: 2 };
    }

    if (window.innerWidth < 900) {
      return { left: 10, right: 10 };
    }

    if (window.innerWidth < 1200) {
      return { left: 20, right: 20 };
    }

    return { right: 30, left: 30 };
  }

  return (
    <Container>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={graphDataset} margin={genGraphMargin()}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            style={{
              fill: "#F7F7F2",
              fontFamily: "Lato",
              fontSize: "12px",
            }}
          />
          <YAxis
            style={{
              fill: "#F7F7F2",
              fontFamily: "Lato",
              fontSize: "12px",
            }}
            domain={["auto", "auto"]}
            label={{
              value: "Rate(%)",
              angle: -90,
              position: "insideLeft",
              fontFamily: "Lato",
              fill: "#F7F7F2",
            }}
          />
          <Tooltip
            isAnimationActive={false}
            itemStyle={{ fontFamily: "Lato" }}
            labelStyle={{
              fontFamily: "Lato",
              color: "#f7f7f2",
            }}
            contentStyle={{
              backgroundColor: "#404040",
              borderRadius: "2px",
              boxShadow: "0px 0px 3px 1px #404040",
            }}
          />
          <Legend
            formatter={(value) => {
              return <LegendItem>{value}</LegendItem>;
            }}
          />
          {periods.map((period, i) => {
            return (
              <Line
                key={period}
                type="stepAfter"
                dataKey={period}
                stroke={palette[i]}
                strokeWidth={3}
                dot={
                  <CustomDot
                    period={period}
                    periods={periods}
                    amendedMetadata={amendedMetadata}
                  />
                }
                isAnimationActive={false}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </Container>
  );
}

const LegendItem = styled.span`
  font-family: ${({ theme }) => theme.textFont};
`;

const Container = styled.div`
  padding: 30px 40px 10px 5px;
  width: 80vw;
  height: 55vh;
`;

import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

import Navbar from "components/navbar";
import { Footer } from "components/Footer";
import { BackButton } from "components/resources/BackButton";
import { Title } from "components/Title";
import SearchBar from "./SearchBar";

import { GET_DNS_METADATA } from "./API";
import DomainCard from "./DomainCard";

export default function DNSLookup() {
  const [query, setQuery] = useState("");
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const search = async () => {
    setLoading(true);
    setErrMessage("");

    if (query === "") {
      setErrMessage("No domain specified.");
      setLoading(false);
      return;
    }

    const domainRegex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;

    if (
      !domainRegex.exec(query) ||
      query.includes("/") ||
      query.split(".").length !== 2
    ) {
      setErrMessage(`Not a valid root domain name (ex. "mortgagebanking.law")`);
      setLoading(false);
      return;
    }

    const names = domains.map((domain) => {
      return domain.url;
    });

    if (names.includes(query)) {
      setLoading(false);
      setErrMessage("Domain already in list.");
      return;
    }

    const metadata = await GET_DNS_METADATA(query);

    if (metadata.valid) {
      const newDomains = [metadata, ...domains];
      setErrMessage("");
      setDomains(newDomains);
      setQuery("");
      setLoading(false);
      return;
    }

    setErrMessage(metadata.statusMessage);
    setLoading(false);
  };

  const updateQuery = (query) => {
    setQuery(query);
    if (query === "") setErrMessage("");
  };

  const deleteDomain = (url) => {
    const newDomains = JSON.parse(JSON.stringify(domains));

    newDomains.forEach((domain, i) => {
      if (domain.url === url) {
        newDomains.splice(i, 1);
      }
    });

    setDomains(newDomains);
  };

  return (
    <Container>
      <BackButtonContainer>
        <BackButton location="/resources" text="< Resources" />
      </BackButtonContainer>
      <Title size="xl" styles={TitleStylesOverride} spanWidth="200px">
        Domain Spoofing Check
      </Title>
      <TextContainer>
        <Description>
          Domain-based Message Authentication, Reporting (DMARC) is how a domain
          owner communicates policies and preferences for message validation,
          disposition, and reporting across a domain. It tells recipients' mail
          servers what to do with a message that purports to be from your domain
          but fails authentication checks because an authorized party did not
          send it. When email fails authentication, DMARC tells the receiver's
          mail service to either quarantine the message or reject it entirely.
          In the absence of these policies a bad actors phishing email may spoof
          your domain and be delivered to your customers' inboxes.
        </Description>
      </TextContainer>
      <SearchContainer>
        <SearchBar query={query} updateQuery={updateQuery} search={search} />
        <Button loading={loading} onClick={search}>
          <Spinner loading={loading} />
          {loading ? "Checking..." : "Check"}
        </Button>
      </SearchContainer>
      <ErrMessage show={errMessage !== ""}>{errMessage}</ErrMessage>
      <DomainContainer>
        {domains.map((domain) => {
          return (
            <DomainCard
              key={domain.url}
              data={domain}
              deleteDomain={deleteDomain}
            />
          );
        })}
      </DomainContainer>
      <Footer slim />
      <Navbar alwaysDisplay />
    </Container>
  );
}

const BackButtonContainer = styled.div`
  width: 100%;

  @media (max-width: 900px) {
    display: none;
  }
`;

const Text = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.text.xs};
  font-family: ${({ theme }) => theme.textFont};
  margin: 25px 0px 30px 0px;

  @media (max-width: 800px) {
    margin: 10px 0px 10px 0px;
  }
`;

const Description = styled(Text)`
  line-height: 28px;
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const DomainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 70px;

  @media (max-width: 800px) {
    padding: 0px 4vw 70px 4vw;
  }
`;

const ErrMessage = styled.p`
  font-size: ${({ theme }) => theme.text.xs};
  font-family: ${({ theme }) => theme.textFont};
  color: #ff0033;

  display: ${({ show }) => (show ? "block" : "none")};
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 0px 10px 0px 10px;
    margin: 20px 0px 20px 0px;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  height: 43px;
  padding: 0px 20px 0px 20px;
  background-color: ${({ theme }) => theme.colors.mainGold};
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  border-radius: 7px;

  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  box-sizing: border-box;

  outline: transparent;
  border: transparent;

  margin-left: 15px;

  &:hover {
    cursor: pointer;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 800px) {
    margin: 15px 0px 0px 0px;
    padding: 0px 20px 0px 20px;
    justify-content: center;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  display: ${({ loading }) => (loading ? "block" : "none")};
  width: 13px;
  height: 13px;
  box-shadow: 0px 0px 4px 0px #878787;
  margin-right: 12px;
  background-color: ${(props) => props.theme.colors.offWhite};
  animation: ${rotate} 1.7s ease-in-out infinite;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  max-width: 77%;
`;

const TitleStylesOverride = `
  margin-bottom: 0px;

  @media (max-width: 900px) {
    margin-top: 15px;
    margin-bottom: 2vh; 
    text-align: center;
  };
`;

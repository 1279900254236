import React from "react";
import styled from "styled-components";

import ChartButton from "./ChartButton";
import Form from "./form/Form";
import NewChartForm from "./NewChartForm";

import {
  GET_CHARTS_METADATA,
  GET_MASTER_METADATA,
  CHECK_TOKEN,
  GET_USERNAME,
} from "./API";

import { Redirect } from "react-router-dom";
import { LogOutButton } from "./LogOutButton";

const DisplayCharts = (props) => {
  const { charts, fetchData } = props;

  if (charts.length === 0 || charts === undefined) {
    return <Message>There are no charts to display.</Message>;
  }

  return charts.map((chart) => {
    return (
      <ChartButton
        id={chart.id}
        key={chart.id}
        title={chart.title}
        fetchData={fetchData}
      />
    );
  });
};

export default class Charts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      charts: [],
      metadata: {},
      isCreatingNew: false,
      authenticated: false,
      loading: true,
      userName: "",
      admin: false,
    };
  }

  async componentDidMount() {
    const authenticated = await CHECK_TOKEN();

    if (authenticated) {
      this.setState({ authenticated: true, loading: false });
      const token = JSON.parse(localStorage.getItem("token"));
      const metadata = await GET_MASTER_METADATA();
      const charts = await GET_CHARTS_METADATA(token);
      const userName = await GET_USERNAME();

      if (charts[0]?.id === "admin") {
        this.setState({ admin: true });
      }

      this.setState({
        charts: charts,
        metadata: metadata,
        userName: userName,
      });
    } else {
      this.setState({ authenticated: false, loading: false });
    }
  }

  fetchData = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const charts = await GET_CHARTS_METADATA(token);
    this.setState({ charts: charts });
  };

  render() {
    const {
      authenticated,
      metadata,
      isCreatingNew,
      charts,
      loading,
      userName,
      admin,
    } = this.state;

    if (authenticated && !loading) {
      return (
        <Page>
          <Content>
            <Header>
              <TitleRow>
                <ChartControls>
                  <Title>Charts</Title>
                  <CreateAChart
                    admin={admin}
                    onClick={() =>
                      this.setState({ isCreatingNew: !isCreatingNew })
                    }
                  >
                    + Create a new chart
                  </CreateAChart>
                </ChartControls>
                <AuthControls>
                  <p>Signed in as: {userName}</p>
                  <LogOutButton>Log Out</LogOutButton>
                </AuthControls>
              </TitleRow>
              <Underline />
            </Header>
            {isCreatingNew && (
              <NewChartForm
                metadata={metadata}
                toggle={() => this.setState({ isCreatingNew: !isCreatingNew })}
              />
            )}
            <DisplayCharts charts={charts} fetchData={this.fetchData} />
          </Content>
        </Page>
      );
    }
    if (!authenticated && !loading) return <Redirect to="/chartLogin" />;
    if (loading) return <p>Loading...</p>;
  }
}

const Page = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 25px 50px 50px 50px;
`;

const Content = styled.div`
  width: 78%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TitleRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const AuthControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ChartControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Title = styled.h1`
  font-weight: normal;
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xl};
  margin: 10px 10px 10px 0px;
`;

const Message = styled.p`
  font-size: ${({ theme }) => theme.text.xs};
  font-family: ${({ theme }) => theme.textFont};
  font-style: italic;
  color: gray;
`;

const Underline = styled.div`
  margin-top: 3px;
  margin-bottom: 5px;
  height: 2px;
  width: 100%;
  background-color: gray;
`;

const CreateAChart = styled.button`
  background-color: transparent;
  padding: 6px 30px 6px 30px;
  border: none;
  outline: 2px solid ${({ theme }) => theme.colors.mainGold};
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  font-weight: normal;
  border-radius: 2px;
  cursor: pointer;
  margin-left: 20px;
  filter: ${({ admin }) => (admin ? "grayscale(1)" : "")};
  opacity: ${({ admin }) => (admin ? "70%" : "100%")};
  pointer-events: ${({ admin }) => (admin ? "none" : "auto")};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.mainGold};
    box-shadow: 1px 1px 2px 1px gray;
    color: white;
  }
`;

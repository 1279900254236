import React, { useState } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";

export const LogOutButton = () => {
  const [loggedIn, setLoggedIn] = useState(true);

  const handleLogOut = () => {
    localStorage.removeItem("token");
    setLoggedIn(!loggedIn);
  };

  if (loggedIn) {
    return <Button onClick={handleLogOut}>Log Out</Button>;
  }
  return <Redirect to="/chartLogin" />;
};

const Button = styled.button``;

import React from "react";
import styled from "styled-components";

export default function DiffText(props) {
  const { show, diffArr } = props;

  if (show) {
    return (
      <Container>
        {diffArr.map((diffObj) => {
          const { added, removed } = diffObj;
          const words = diffObj.value.split(" ").filter((str) => str !== "");

          return words.map((word) => {
            return (
              <Span
                key={word}
                added={added}
                removed={removed}
              >{`${word} `}</Span>
            );
          });
        })}
      </Container>
    );
  }
  return null;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const Span = styled.span`
  background-color: ${({ added, removed }) => {
    if (added) return "#e1a915; color: #202020;";
    if (removed) return "transparent";
    return "transparent";
  }};
  text-decoration-line: ${({ removed }) => (removed ? "line-through" : "")};
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  padding: 0px;
  line-height: 23px;
  white-space: pre;
`;

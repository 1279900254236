import { BACKEND_URL } from "../../API_ENDPOINT";

export const POST_DATA = async (apiData, chartID, admin, preview) => {
  let url = `${BACKEND_URL}/charts/update`;
  console.log("admin", admin);
  if (admin) url = `${BACKEND_URL}/admin/chart/update`;

  let result = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      apiData: apiData,
      chartID: chartID,
      preview: preview,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  result = await result.json();
  return result.status;
};

export const FETCH_DATA = async (id, admin) => {
  let url = `${BACKEND_URL}/charts/data`;
  if (admin) url = `${BACKEND_URL}/admin/chart`;

  let data = await fetch(url, {
    method: "POST",
    body: JSON.stringify({ id: id }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  data = await data.json();
  delete data[0]._id;
  return data[0];
};

export const RESTORE_CHART_VERSION = async (id, date) => {
  let url = `${BACKEND_URL}/admin/chart/restore`;

  let result = await fetch(url, {
    method: "POST",
    body: JSON.stringify({ id: id, date: date }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const json = await result.json();
  return json.completed;
};

export const FETCH_VERSIONS = async () => {
  let result = await fetch(`${BACKEND_URL}/admin/chart/versions`);
  result = await result.json();
  return result.versions;
};

export const FETCH_CITATIONS = async () => {
  let data = await fetch(`${BACKEND_URL}/charts/citations`);

  data = await data.json();
  delete data[0]._id;
  return data[0];
};

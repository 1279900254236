import React from "react";
import styled from "styled-components";
import StatusIcon from "./StatusIcon";
import Details from "./SPFDetails";

export default function SPFRecord(props) {
  const { statusMessage, pass } = props.record;

  return (
    <Container>
      <Header>
        <Title>SPF Record</Title>
        <Status>
          <StatusIcon pass={pass} />
          <StatusMessage>{statusMessage}</StatusMessage>
        </Status>
      </Header>

      <Text>
        An SPF record identifies the mail servers and domains that are allowed
        to send email on behalf of your domain. Receiving servers check your SPF
        record to verify that incoming messages that appear to be from your
        organization are sent from servers allowed by you.
      </Text>
      <Details record={props.record} />
    </Container>
  );
}

const Status = styled.div`
  display: flex;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const Title = styled.h1`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.md};
  margin-right: 13px;

  @media (max-width: 800px) {
    margin: 15px 0px 0px 0px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  line-height: 26px;
  margin-top: 0px;
  margin-bottom: 15px;

  @media (max-width: 800px) {
    font-size: ${({ theme }) => theme.text.xxs};
    line-height: 24px;
  }
`;

const StatusMessage = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.sm};
  margin-left: 10px;
  margin-bottom: 15px;

  @media (max-width: 800px) {
    margin-left: 6px;
  }
`;

import { nanoid } from "nanoid";
import React, { useState } from "react";
import styled from "styled-components";
import Chevron from "./Chevron.icon";

export default function Dropdown(props) {
  const [expanded, toggleExpanded] = useState(false);
  const { selected, updateSelected } = props;
  const { id, body, type, children } = props.data;

  const criteria = ["State", "Role", "Collateral Type"];
  const dropdownChildren = [];

  children.forEach((childObj) => {
    if (criteria.includes(childObj.type)) {
      dropdownChildren.push(
        <Dropdown
          key={childObj.id}
          data={childObj}
          selected={selected}
          updateSelected={updateSelected}
        />
      );
    }
  });

  if (criteria.includes(type)) {
    return (
      <Container key={id}>
        <Content>
          <CheckBox
            type="checkbox"
            onChange={(evt) => {
              evt.stopPropagation();
              if (selected.includes(id)) {
                const newSelected = selected;
                newSelected.splice(selected.indexOf(id), 1);
                updateSelected(newSelected);
              } else {
                const newSelected = selected;
                newSelected.push(id);
                updateSelected(newSelected);
              }
            }}
            name={body}
            show={type !== "State"}
            checked={selected.includes(id)}
          />
          <Title
            onClick={(evt) => {
              evt.stopPropagation();
              toggleExpanded(!expanded);
            }}
          >
            <Chevron
              expanded={expanded}
              hovered={false}
              leaf={dropdownChildren.length == 0}
            />
            <Text>{body}</Text>
          </Title>
        </Content>
        {expanded &&
          dropdownChildren.map((child) => {
            return child;
          })}
      </Container>
    );
  }
  return null;
}

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-direction: flex-start;
  align-items: center;
`;

const CheckBox = styled.input`
  margin-right: 15px;
  margin-left: 5px;
  height: 15px;
  width: 15px;
  cursor: pointer;
  display: ${({ show }) => (show ? "block" : "none")};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 0px 5px 15px;
`;

const Text = styled.p`
  font-size: ${({ theme }) => theme.text.sm};
  font-family: ${({ theme }) => theme.textFont};
  margin: 7px 0px 7px 0px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
`;

import styled from "styled-components";

export const Button = styled.button`
  display: ${({ show }) => (show ? "flex" : "none")};
  justify-content: center;
  padding: 8px 5px 8px 5px;
  box-sizing: border-box;
  width: 310px;
  font-family: ${({ theme }) => theme.textFont};
  font-size: 16px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.darkGray};
  outline: 2px solid ${({ theme }) => theme.colors.mainGold};
  border: none;
  border-radius: 3px;
  box-shadow: 1px 1px 1px lightgray;
  cursor: pointer;
  transition: transform 0.1s linear;
  margin: 15px 0px 5px 0px;

  &:hover {
    box-shadow: 1px 1px 2px 1px lightgray;
    background-color: ${({ theme }) => theme.colors.mainGold};
    transition: background-color 0.1s ease;
    transition: transform 0.1s linear;
    transform: translateY(-1px);
  }

  @media (max-width: 900px) {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
  }
`;

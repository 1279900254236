import React from "react";
import styled from "styled-components";

import CreateChartForm from "./CreateChartForm";
import EditCriteriaForm from "./EditCriteriaForm";

const parseCriteriaIds = (chartData) => {
  const ids = [];
  const states = Object.keys(chartData);
  const criteria = ["State", "Role", "Collateral Type"];

  const parseHierarchy = (objArray) => {
    objArray.forEach((obj) => {
      if (criteria.includes(obj.type)) {
        ids.push(obj.id);
      }

      if (obj.children) {
        return parseHierarchy(obj.children);
      }
    });
  };

  states.forEach((state) => {
    parseHierarchy(chartData[state]);
  });

  return ids;
};

export default class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        title: "",
        categories: [],
        states: [],
      },
      sample: null,
      // an array of object ids representing which criteria cells are currently selected.
      selected: [],
    };
  }

  updateSample = (sample) => {
    const selected = parseCriteriaIds(sample);
    this.setState({ sample, selected });
  };

  updateForm = (form) => {
    this.setState({ form });
  };

  updateSelected = (selected) => {
    this.setState({ selected });
  };

  toggleCreateForm = () => {
    this.setState({ sample: null });
  };

  render() {
    const { sample, selected, form } = this.state;
    const { toggle, metadata } = this.props;

    return (
      <Container>
        {!sample && (
          <CreateChartForm
            metadata={metadata}
            updateSample={this.updateSample}
            updateForm={this.updateForm}
            form={form}
            toggle={toggle}
          />
        )}
        {sample && (
          <EditCriteriaForm
            sample={sample}
            updateSample={this.updateSample}
            selected={selected}
            updateSelected={this.updateSelected}
            toggleCreateForm={this.toggleCreateForm}
          />
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  position: fixed;
  left: -15px;
`;

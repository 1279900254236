import React from "react";
import styled from "styled-components";

import Grid from "./Grid";
import Citations from "./citations/index";
import StateControls from "./StateControls";

export default class Chart extends React.Component {
  render() {
    const { data, titleData, citations, updateChildren } = this.props;
    if (data && titleData && citations) {
      return (
        <Container>
          <Grid
            data={this.props.data}
            titleData={this.props.titleData}
            citations={this.props.citations}
            updateChildren={updateChildren}
          />
          <StateControls />
          <Citations citations={this.props.citations} />
        </Container>
      );
    } else {
      return <p>Rendering data....</p>;
    }
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0px 100px 0px;
  padding: 20px 50px 20px 30px;
`;

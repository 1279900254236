import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { palette } from "./palette";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import {
  checkIfPeriodsAmended,
  genAmendedGraphs,
  getAffectedPeriods,
} from "./utils";

const EmptyDot = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5" cy="5" r="5" fill="none" />
    </svg>
  );
};

export function AmendedGraphs(props) {
  const { metadata, periods, loading } = props;

  const [graphs, setGraphs] = useState([]);
  const [periodsAmended, setPeriodsAmended] = useState(false);

  useEffect(() => {
    const graphs = genAmendedGraphs(metadata, periods);
    const periodsAmended = checkIfPeriodsAmended(graphs, periods);

    setGraphs(graphs);
    setPeriodsAmended(periodsAmended);
  }, [metadata, periods]);

  function genGraphMargin() {
    if (window.innerWidth < 900) {
      return { bottom: 10, left: 0, right: 0 };
    }

    return { left: 20, right: 20 };
  }

  function formatLegend(value, affectedPeriods) {
    if (affectedPeriods.includes(value))
      return <LegendItem>{value} (affected)</LegendItem>;

    return <LegendItem>{value}</LegendItem>;
  }

  function constrainYAxis(dataMin, dataMax) {
    console.log({ dataMin, dataMax });
    return [
      parseFloat(dataMin - 0.1).toFixed(3),
      parseFloat(dataMax + 0.1).toFixed(3),
    ];
  }

  const dates = Object.keys(metadata);

  return (
    <Container id="amendedgraphs" show={periodsAmended && !loading}>
      <TitleContainer>
        <Title>Amended Weeks</Title>
        <Underline></Underline>
      </TitleContainer>

      <MessageContainer>
        <Message show={true}>
          The week(s) displayed below were later amended by the CFPB after being
          published.
        </Message>
      </MessageContainer>

      {graphs.map((graph, i) => {
        const affectedPeriods = getAffectedPeriods(graph, periods);
        console.log("amended graph", graph);
        return (
          <ChartContainer key={i} show={affectedPeriods.length > 0}>
            <GraphTitle>{`Amended Rate Data Week of ${dates[i]}`}</GraphTitle>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={graph} margin={genGraphMargin()}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  style={{
                    fill: "#F7F7F2",
                    fontFamily: "Lato",
                    fontSize: "12px",
                  }}
                />
                <YAxis
                  type="number"
                  style={{
                    fill: "#F7F7F2",
                    fontFamily: "Lato",
                    fontSize: "12px",
                  }}
                  domain={([dataMin, dataMax]) =>
                    constrainYAxis(dataMin, dataMax)
                  }
                  label={{
                    value: "Rate (%)",
                    angle: -90,
                    position: "left",
                    fill: "#F7F7F2",
                    fontFamily: "Lato",
                    dy: -35,
                    dx: 15,
                  }}
                />
                <Tooltip
                  isAnimationActive={false}
                  itemStyle={{ fontFamily: "Lato" }}
                  labelStyle={{
                    fontFamily: "Lato",
                    fontWeight: "bold",
                    color: "#f7f7f2",
                  }}
                  contentStyle={{
                    backgroundColor: "#404040",
                    borderRadius: "2px",
                    boxShadow: "0px 0px 3px 1px #404040",
                  }}
                />
                {periods.length < 7 && (
                  <Legend
                    formatter={(value) => formatLegend(value, affectedPeriods)}
                  />
                )}
                {periods.map((period, i) => {
                  return (
                    <Line
                      key={period}
                      type="stepAfter"
                      dataKey={period}
                      stroke={palette[i]}
                      strokeWidth={3}
                      dot={<EmptyDot />}
                    />
                  );
                })}
              </LineChart>
            </ResponsiveContainer>
          </ChartContainer>
        );
      })}
    </Container>
  );
}

const LegendItem = styled.span`
  font-family: ${({ theme }) => theme.textFont};
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: space-between;
`;

const Message = styled.div`
  margin-left: 5px;
  display: ${({ show }) => (show ? "block" : "none")};
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  font-style: ${({ italic }) => (italic ? "italic" : "none")};

  @media (max-width: 900px) {
    text-align: center;
  }

  @media (max-width: 600px) {
    font-size: ${({ theme }) => theme.text.xxs};
  }
`;

const Title = styled.p`
  font-family: ${({ theme }) => theme.titleFont};
  font-size: ${({ theme }) => theme.text.lg};
  margin: 20px 0px 5px 0px;
`;

const TitleContainer = styled.div`
  margin-top: 0px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Underline = styled.span`
  background: #e1a915;
  width: 120px;
  height: 3px;
`;

const GraphTitle = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.sm};
  margin: 10px 0px 20px 40px;
  color: white;

  @media (max-width: 800px) {
    text-align: center;
  }
`;

const Container = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  margin: 20px 0px 60px 0px;
`;

const ChartContainer = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  background-color: #202020;
  padding: 10px 30px 20px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 30px 0px 30px 0px;
  width: 80vw;
  height: 50vh;

  @media (max-width: 900px) {
    height: 60vh;
  }
`;

import React from "react";
import styled from "styled-components";

export function CellEditButton(props) {
  const { toggleEdit, show } = props;
  return (
    <Container show={show}>
      <Button onClick={toggleEdit}>Edit</Button>
      {/* <Icon /> */}
    </Container>
  );
}

const Container = styled.div`
  margin-top: 5px;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
`;

const Button = styled.div`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xxs};
  outline: 2px solid ${({ theme }) => theme.colors.mainGold};
  color: #2e2e2e;
  letter-spacing: 0.03rem;
  font-weight: bold;
  border: none;
  border-radius: 2px;
  padding: 5px 18px 5px 18px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.offWhite};

  &:hover {
    transform: translateY(-1px);
    box-shadow: 1px 2px 3px 0px gray;
    background-color: ${({ theme }) => theme.colors.mainGold};
    color: white;
  }
`;

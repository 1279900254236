import React from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { checkCitation } from "./utils";

class List extends React.Component {
  render() {
    const { provided, innerRef, children, isDragging } = this.props;

    return (
      <ListContainer
        {...provided.droppableProps}
        ref={innerRef}
        isDragging={isDragging}
      >
        {children}
      </ListContainer>
    );
  }
}

const ListContainer = styled.div`
  background-color: ${({ isDragging, theme }) =>
    isDragging ? theme.colors.mainGold : "lightgray"};
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  width: 250px;
  border-radius: 3px;
`;

class DragCell extends React.Component {
  render() {
    const { provided, innerRef, body, citations, isDragging } = this.props;

    return (
      <DragCellContainer
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        isDragging={isDragging}
        ref={innerRef}
      >
        <DragCellText>{checkCitation(citations, body)}</DragCellText>
      </DragCellContainer>
    );
  }
}

const DragCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  box-shadow: ${({ isDragging }) => (isDragging ? "1px 1px 4px 0px gray" : "")};
  outline: ${({ isDragging }) => (isDragging ? "1px solid black" : "")};
  flex-grow: 1;
  padding: 0px 10px 0px 10px;
  height: 100%;
  margin: 20px 10px 20px 10px;
  border-radius: 3px;
  z-index: 0;
`;

const DragCellText = styled.p`
  text-align: center;
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ name }) => (name ? "16px" : "14px")};
  line-height: 23px;
`;

export default class DragDropContainer extends React.Component {
  onDragEnd = (result) => {
    if (!result.destination) return;

    const newChildren = this.reoder(
      this.props.children,
      result.source.index,
      result.destination.index
    );

    this.props.updateChildren(this.props.parentID, newChildren);
  };

  reoder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  render() {
    if (this.props.children && this.props.coords) {
      return (
        <DragDropContainerDiv coords={this.props.coords}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <List
                  provided={provided}
                  innerRef={provided.innerRef}
                  isDragging={snapshot.isDraggingOver}
                >
                  {this.props.children.map((obj, i) => {
                    return (
                      <Draggable draggableId={obj.id} index={i} key={obj.id}>
                        {(provided, snapshot) => (
                          <DragCell
                            key={obj.body}
                            provided={provided}
                            innerRef={provided.innerRef}
                            citations={this.props.citations}
                            isDragging={snapshot.isDragging}
                            body={obj.body}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </DragDropContainerDiv>
      );
    } else {
      return <p>Loading dnd</p>;
    }
  }
}

const DragDropContainerDiv = styled.div`
  grid-row: ${({ coords }) => `${coords[0]}/${coords[1]}`};
  margin: 5px 10px 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const GET_FIXED_DATA = async () => {
  const result = await fetch(
    "https://s3.amazonaws.com/cfpb-hmda-public/prod/apor/YieldTableFixed.txt"
  );
  return await result.text();
};

export const GET_ADJUSTABLE_DATA = async () => {
  const result = await fetch(
    "https://s3.amazonaws.com/cfpb-hmda-public/prod/apor/YieldTableAdjustable.txt"
  );
  return await result.text();
};

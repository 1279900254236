import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "../Button.style.js";

import { formatDate } from "../utils";

export function Input(props) {
  const {
    handleGenerate,
    setCalcEndDate,
    setCalcStartDate,
    calcSynced,
    firstPageLoad,
    setCalcSynced,
  } = props;
  const { state, setState } = props;

  const { error } = state;
  const [inputAPR, setInputApr] = useState("");
  const [inputRateSetDate, setInputRateSetDate] = useState(
    formatDate(new Date())
  );

  const firstDate = formatDate("2017-01-03");
  const firstDateOffset = formatDate("2017-01-09");
  const lastDate = formatDate(getCurrentMonday());
  const lastDateOffset = getNextMonday(new Date());

  function getCurrentMonday() {
    if (new Date().getDay() === 1) return new Date();
    if (new Date().getDay() === 0) {
      return getPreviousMonday(new Date());
    }

    return new Date(
      new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
    );
  }

  function getPreviousMonday(rateSetDate) {
    const monday = new Date(
      new Date(rateSetDate).setDate(
        new Date(rateSetDate).getDate() - (6 + new Date(rateSetDate).getDay())
      )
    );
    return monday;
  }

  function getNextMonday(rateSetDate) {
    const monday = new Date(
      new Date(rateSetDate).setDate(
        new Date(rateSetDate).getDate() + (8 - new Date(rateSetDate).getDay())
      )
    );
    return monday;
  }

  function handleRateUpdate(evt) {
    const rateSetDate = evt.target.value;

    if (rateSetDate === "") return;
    if (rateSetDate.length > 10) return;

    setCalcSynced(false);
    setInputRateSetDate(rateSetDate);

    let year = new Date(rateSetDate).getFullYear();

    let intendedStartDate = formatDate(getPreviousMonday(rateSetDate));

    if (year < 2017) {
      intendedStartDate = firstDate;
    }

    if (intendedStartDate > lastDate) {
      intendedStartDate = lastDate;
    }

    setCalcStartDate(intendedStartDate);

    let intendedEndDate = getNextMonday(rateSetDate);

    let intendedEndYear = new Date(intendedEndDate).getFullYear();

    if (isNaN(intendedEndYear) || intendedEndYear < 2017) {
      intendedEndDate = firstDateOffset;
    }

    if (new Date(intendedEndDate) > new Date(lastDateOffset)) {
      intendedEndDate = lastDateOffset;
    }

    intendedEndDate = formatDate(intendedEndDate);

    setCalcEndDate(intendedEndDate);
  }

  function handleUpdate(evt) {
    const { value } = evt.target;

    if (value.match(/^\d{1,}(\.\d{0,8})?$/)) {
      setState((state) => ({ ...state, error: "" }));
    } else {
      setState((state) => ({
        ...state,
        error: "Please enter a valid number.",
      }));
    }

    setCalcSynced(false);
    setInputApr(value);
  }

  return (
    <Container>
      <InputRow>
        <APRInputContainer>
          <Text>APR:</Text>
          <APRInput
            type="text"
            placeholder="Enter APR %"
            value={inputAPR}
            onChange={(evt) => {
              handleUpdate(evt);
            }}
          />
          <Text>Rate Set Date:</Text>
          <SetDateInput
            type="date"
            name="rateSetDate"
            value={inputRateSetDate}
            min={"2017-01-02"}
            onChange={(evt) => handleRateUpdate(evt)}
          />
        </APRInputContainer>
        <Button
          onClick={() => handleGenerate(inputAPR, inputRateSetDate, error)}
          show={firstPageLoad || !calcSynced}
        >
          + Generate Spread Table
        </Button>
      </InputRow>
      <Error show={error !== "" || error !== undefined}>{error}</Error>
    </Container>
  );
}

const InputRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0px 0px 0px 0px;

  @media (max-width: 900px) {
    margin: 0px 0px 15px 0px;
  }
`;

const APRInput = styled.input`
  max-width: 130px;
  padding: 5px 0px 5px 10px;
  font-family: ${({ theme }) => theme.textFont};
  outline: none;
  margin-right: 10px;

  @media (max-width: 900px) {
    margin-right: 0px;
  }
`;

const SetDateInput = styled.input`
  padding: 5px 0px 5px 10px;
  font-family: ${({ theme }) => theme.textFont};
  outline: none;
  box-sizing: border-box;
  margin-right: 10px;

  @media (max-width: 900px) {
    margin-right: 0px;
    width: 100%;
  }
`;

const APRInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;

  @media (max-width: 900px) {
    margin-bottom: 0px;
    flex-direction: column;
  }
`;

const Error = styled.p`
  display: ${({ show }) => (show ? "block" : "none")};
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  margin: 5px 0px 5px 0px;
  color: red;
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;

  @media (max-width: 900px) {
    margin-bottom: 5px;
  }
`;

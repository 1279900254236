import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "./Input";
import { Spinner } from "../Spinner";
import { AmendedLegend } from "./AmendedLegend";
import { AmendedMessage } from "../AmendedMessage";

import {
  genTableData,
  formatRateSetDate,
  formatDate,
  containsAmendedData,
  containsAmendedDataAtRSD,
  getParsedAmendedWeeks,
} from "../utils";
import { GET_WEEKS } from "../API";

const intialState = {
  weeks: [],
  loading: false,
  apr: "",
  tableData: null,
  tablePeriods: [],
  amendedWeeks: [],
  containsAmended: false,
  containsAmendedAtRSD: false,
  rateSetDate: formatDate(new Date().toLocaleDateString()),
  beyondPublishedData: false,
  error: "",
};

export default function Calculator(props) {
  const {
    periods,
    calcStartDate,
    calcEndDate,
    loanType,
    calcSynced,
    setCalcSynced,
    setCalcEndDate,
    setCalcStartDate,
  } = props;

  const [state, setState] = useState(intialState);
  const [firstPageLoad, setFirstPageLoad] = useState(true);

  function checkIfBeyondPublishedData(rateSetDate, weeks) {
    if (!weeks) return false;

    const rsd = new Date(formatRateSetDate(rateSetDate));
    const firstWeek = new Date("1/02/2017");

    let latestWeek = new Date();
    if (rsd > latestWeek) latestWeek = weeks.at(-1);

    const latestWeekEnd = new Date(
      new Date().setDate(new Date(latestWeek.date).getDate() + 6)
    );

    return rsd > latestWeekEnd || rsd < firstWeek;
  }

  async function handleGenerate(apr, rateSetDate, error) {
    if (!apr || apr === "") {
      setState((state) => ({ ...state, error: "Please enter an APR." }));
      return;
    }

    if (error !== "") return;

    setState((state) => ({
      ...state,
      loading: true,
      containsAmended: false,
      beyondPublishedData: false,
    }));

    const weeks = await GET_WEEKS(
      calcStartDate,
      calcEndDate,
      loanType,
      "calculator"
    );
    const formattedRsd = formatRateSetDate(rateSetDate);
    const tableData = genTableData(apr, weeks, periods, formattedRsd);
    const containsAmended = containsAmendedData(tableData);
    const containsAmendedAtRSD = containsAmendedDataAtRSD(tableData);
    const amendedWeeks = getParsedAmendedWeeks(weeks);
    const beyondPublishedData = checkIfBeyondPublishedData(rateSetDate, weeks);

    setCalcSynced(true);
    setFirstPageLoad(false);

    setState((state) => ({
      ...state,
      apr,
      rateSetDate,
      beyondPublishedData,
      tablePeriods: periods,
      tableData,
      amendedWeeks,
      containsAmended,
      containsAmendedAtRSD,
      beyondPublishedData,
      loading: false,
    }));
  }

  const toInteger = (period) => {
    return parseInt(period.split(" ")[0]);
  };

  function checkIfGuessed(cell) {
    const startDate = new Date("6/15/2023");
    if (cell.type !== "publication date") return false;
    return new Date(cell.body) < startDate;
  }

  function printAmendedWeeks(amendedWeeks) {
    let str = "";

    if (amendedWeeks.length == 1) {
      str = str + amendedWeeks[0] + " was amended but is";
      return str;
    }

    amendedWeeks.forEach((week, i) => {
      if (i + 1 !== amendedWeeks.length) {
        str = str + week + ", ";
      } else {
        str = str + week;
      }
    });

    return str + " were amended but are";
  }

  const {
    loading,
    apr,
    amendedWeeks,
    containsAmended,
    containsAmendedAtRSD,
    tableData,
    tablePeriods,
    rateSetDate,
    beyondPublishedData,
  } = state;

  return (
    <Container>
      <Input
        firstPageLoad={firstPageLoad}
        calcSynced={calcSynced}
        setCalcSynced={setCalcSynced}
        handleGenerate={handleGenerate}
        state={state}
        setState={setState}
        setCalcStartDate={setCalcStartDate}
        setCalcEndDate={setCalcEndDate}
      />

      <Title show={tableData !== null}>
        Rate Spread at {apr}% APR for {formatRateSetDate(rateSetDate)}
      </Title>

      <Disclaimer show={beyondPublishedData}>
        Rate set date lies outside published data.
      </Disclaimer>

      <AmendedMessageContainer>
        <AmendedMessage show={containsAmended} tabSelected={"calculator"} />
      </AmendedMessageContainer>

      <AmendedDisclaimer show={containsAmended && !containsAmendedAtRSD}>
        Week of {printAmendedWeeks(amendedWeeks)} outside the rate set date.
      </AmendedDisclaimer>

      <TableContainer show={tableData !== null && !loading} synced={calcSynced}>
        <RateTable>
          <tbody>
            <tr>
              <TitleCell>Week Of</TitleCell>
              <TitleCell>Date Published</TitleCell>
              {tablePeriods.map((period) => {
                tablePeriods.sort((a, b) => {
                  return toInteger(a) - toInteger(b);
                });
                return [
                  <TitleCell key={period + "rate"}>{period} (rate)</TitleCell>,
                  <TitleCell key={period + "spread"}>
                    {period} (spread)
                  </TitleCell>,
                ];
              })}
            </tr>
            {tableData &&
              tableData.map((row, i) => {
                return (
                  <tr key={"row" + i}>
                    {row.map((cell, i) => {
                      const { body, amended, containsRateSetDate, activeWeek } =
                        cell;
                      let rateCell = false;
                      if (i !== 0 && i % 2 === 0) rateCell = true;
                      return (
                        <ValueCell
                          key={"cell" + i}
                          amended={amended}
                          containsRateSetDate={containsRateSetDate}
                          active={activeWeek}
                          rateCell={rateCell}
                        >
                          {`${body}${checkIfGuessed(cell) ? "*" : ""}`}
                        </ValueCell>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </RateTable>
      </TableContainer>

      <LoadingContainer show={loading}>
        <Spinner />
        <LoadingText>Loading...</LoadingText>
      </LoadingContainer>

      <Disclaimer
        show={tableData && new Date(rateSetDate) <= new Date("6/15/2023")}
      >
        (*) denotes unverfied publication dates.
      </Disclaimer>
      <AmendedLegend show={containsAmendedAtRSD} />
    </Container>
  );
}

const AmendedMessageContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`;

const Disclaimer = styled.p`
  display: ${({ show }) => (show ? "block" : "none")};
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  margin-top: 2px;

  @media (max-width: 900px) {
    font-size: ${({ theme }) => theme.text.xxs};
  }
`;

const AmendedDisclaimer = styled(Disclaimer)`
  margin-top: 10px;
`;

const LoadingText = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.sm};
  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  margin-bottom: 50px;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TableContainer = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  ${({ synced }) => (synced ? "" : "filter: opacity(0.9) blur(2px); ")};
  width: 100%;
  overflow-x: auto;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const Cell = styled.td`
  text-align: ${({ left }) => (left ? "left" : "center")};
  font-family: ${({ theme }) => theme.textFont};
  border: 2px solid black;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 24px;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    font-size: ${({ theme }) => theme.text.xs};
  }

  @media (max-width: 800px) {
    font-size: ${({ theme }) => theme.text.xxs};
  }
`;

const TitleCell = styled(Cell)`
  font-weight: bold;
  padding-left: ${({ left }) => (left ? "15px" : "0px")};
  background-color: ${({ theme }) => theme.colors.darkBlue};
  color: white;
  padding: 5px 50px 5px 50px;
`;

const ValueCell = styled(Cell)`
  border: ${({ containsRateSetDate }) =>
    containsRateSetDate ? "2px solid #202020" : "2px solid black"};
  background-color: ${({ containsRateSetDate, amended, active, rateCell }) => {
    if (active) return "#e1a915";
    if (containsRateSetDate && amended) return "#F5DA8F";
    if (rateCell) return "#dedede";
    return "transparent";
  }};
  color: ${({ isGuessed }) => (isGuessed ? "blue" : "black")};
  padding: 5px 50px 5px 50px;
`;

const RateTable = styled.table`
  width: 100%;
  table-layout: auto;
`;

const Title = styled.p`
  font-size: ${({ theme }) => theme.text.md};
  font-family: ${({ theme }) => theme.textFont};
  margin-bottom: 15px;
  margin-top: 8px;
  display: ${({ show }) => (show ? "flex" : "none")};

  @media (max-width: 900px) {
    font-size: ${({ theme }) => theme.text.xs};
  }
`;

import { BACKEND_URL } from "API_ENDPOINT";

// Returns Atlas search results for a given term from the backend.
export const API_FETCH_RESULTS = async (query) => {
  let results = await fetch(`${BACKEND_URL}/production/search?term=${query}`);

  let parsedResults = await results.json();

  return parsedResults;
};

import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { globalStyles } from "./globalStyles";
import { ThemeProvider } from "styled-components";
import "./index.css";

import Homepage from "pages/homepage/Homepage";
import ULICalculator from "pages/resources/uli-calculator/index";
import DNSLookup from "pages/resources/dnslookup/index";
import Resources from "pages/resources/Resources";
import YieldGraph from "pages/resources/yield-graph/index";
import PracticeAreas from "pages/practice-areas/PracticeAreas";
import Initiatives from "pages/initiatives/Initiatives";
import SearchGlossary from "pages/glossary-search";
import Articles from "pages/articles";
import AdminRouter from "pages/admin/AdminRouter";
import AcronymReview from "pages/admin/AcronymReview";
import AcronymSubmission from "pages/glossary-search/AcronymSubmission";
import ChartRouter from "./pages/chart/charts/ChartRouter";
import ChartLogin from "./pages/chart/charts/ChartLogin";
import PageNotFound from "pages/PageNotFound";

import history from "./history";
import APOR from "pages/resources/apor";

function App() {
  return (
    <ThemeProvider theme={globalStyles}>
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={Homepage} />

          <Route path="/resources">
            <Resources />
          </Route>

          <Route path="/yield-graph">
            <YieldGraph />
          </Route>

          <Route path="/articles">
            <Articles />
          </Route>

          <Route path="/check-digit-calculator">
            <ULICalculator />
          </Route>

          <Route path="/apor">
            <APOR />
          </Route>

          <Route path="/glossary-search">
            <SearchGlossary />
          </Route>

          <Route path="/dns-lookup">
            <DNSLookup />
          </Route>

          <Route path="/single-post/2017/05/28/uli-check-digit-calculator">
            <ULICalculator />
          </Route>

          <Route path="/initiatives">
            <Initiatives />
          </Route>

          <Route path="/practice-areas">
            <PracticeAreas />
          </Route>

          <Route path="/admin">
            <AdminRouter />
          </Route>

          <Route path="/submit-an-acronym">
            <AcronymSubmission />
          </Route>

          <Route path="/acronym-editor">
            <AcronymReview />
          </Route>

          <Route path="/charts">
            <ChartRouter />
          </Route>

          <Route path="/chartLogin">
            <ChartLogin />
          </Route>

          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;

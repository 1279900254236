import React, { useState, useContext } from "react";
import styled from "styled-components";

import { EditContext } from "./EditContext";

export default function ColumnControls() {
  const [inputMode, setInputMode] = useState(false);
  const [text, setText] = useState("");
  const context = useContext(EditContext);

  const handleDiscard = () => {
    setInputMode(false);
    setText("");
  };

  const handleSave = () => {
    // call props.update
    context.addAColumn(text);
    setInputMode(false);
    setText("");
  };

  const handleChange = (evt) => {
    setText(evt.target.value);
  };

  if (!inputMode) {
    return (
      <>
        <Button onClick={() => setInputMode(!inputMode)}>+ Add a column</Button>
        <RemoveButton onClick={context.deleteLastColumn}>
          Remove Last Column
        </RemoveButton>
      </>
    );
  }

  if (inputMode) {
    return (
      <Container>
        <Input onChange={handleChange} value={text} />
        <DiscardButton onClick={handleDiscard}>Discard</DiscardButton>
        <SaveButton onClick={handleSave}>Save</SaveButton>
      </Container>
    );
  }
}

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: 2px solid ${({ theme }) => theme.colors.mainGold};
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 0px gray;
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  padding: 5px 15px 5px 15px;
  margin: 2px 15px 0px 15px;
  cursor: pointer;
  z-index: 5;
`;

const Input = styled.input`
  z-index: 5;
`;

const SaveButton = styled(Button)``;

const DiscardButton = styled(Button)``;

const RemoveButton = styled(Button)`
  outline: 2px solid red;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

import { BACKEND_URL } from "API_ENDPOINT";
import { sortArticleDataByDate } from "./articleHub/utils";

export const FETCH_ARTICLE_DATA = async () => {
  const response = await fetch(`${BACKEND_URL}/articles/staging`);
  const json = await response.json();

  return sortArticleDataByDate(json);
};

export const FETCH_ARTICLE = async (filename) => {
  try {
    let response = await fetch(
      `${BACKEND_URL}/markdown/staging/${filename}.md`
    );

    const responseObj = await response.json();

    if (responseObj.validArticle && responseObj.text) {
      return responseObj.text;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const FETCH_IMG_BASE64 = async (imgName) => {
  try {
    let response = await fetch(`${BACKEND_URL}/images/staging/${imgName}`);

    const responseObj = await response.json();
    return responseObj;
  } catch {
    return false;
  }
};

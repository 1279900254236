import React, { useContext } from "react";
import styled from "styled-components";
import { EditContext } from "../EditContext";

export function VersionCard(props) {
  const { date } = props;
  const context = useContext(EditContext);

  const newDate = new Date(date);
  const newTimeStr = newDate.toTimeString();
  const time = newTimeStr.slice(0, 8);

  if (date) {
    return (
      <Container onClick={() => context.setChartVersion(date)}>
        <Bookmark show={context.previewDate === date} />
        <Content>
          <Title>{newDate.toDateString()}</Title>
          <Row>
            <Text>{time}</Text>
            <Username>User: Admin</Username>
          </Row>
        </Content>
      </Container>
    );
  }
  return null;
}

const Text = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  margin: 5px 0px 5px 0px;
`;

const Username = styled(Text)`
  margin-left: 12px;
`;

const Title = styled(Text)`
  font-size: ${({ theme }) => theme.text.sm};
`;

const Bookmark = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  width: 11px;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.mainGold};
  margin-top: 2px;
  margin-left: 3px;
  align-content: center;
  align-self: flex-start;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 30px 0px;
  padding: 10px;
  width: 240px;
  background-color: ${({ theme }) => theme.colors.offWhite};
  border: 1px solid transparent;
  cursor: pointer;
  box-shadow: 1px 1px 1px 0px lightgray;
  border-radius: 5px;

  &:hover {
    box-shadow: 1px 1px 2px 1px lightgray;
  }
`;

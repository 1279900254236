import { nanoid } from "nanoid";
import longDummyData from "./longDummyData.json";
import { diffWords, diffChars } from "diff";

export const findObjRowSize = (obj) => {
  /* Check how many children a given element in our objArray
  has recursively. Ie, a branch with 2 sub-branches, each with
  2 leaves, will return a size of 4. */
  let value = 0;

  function findSmallestChildren(objArray) {
    if (objArray && objArray.length > 0) {
      objArray.forEach((obj) => {
        const hasChildren = obj.children && obj.children.length > 0;

        if (hasChildren) {
          findSmallestChildren(obj.children);
        } else {
          value = value + 1;
        }
      });
    }
  }

  findSmallestChildren(obj.children);
  return value;
};

export const genTitleRowArray = (canonicalData) => {
  /* Create a seperate array containing just the 
  column titles to render as the column titles*/
  const titleRowArray = [];

  canonicalData.forEach((column) => {
    titleRowArray.push(column[0].type);
  });

  return titleRowArray;
};

export function parseDuplicateCellData(canonicalData, data) {
  /* Calls a recursive function to eleminate duplicate cell data for the last 
  row of canonical data, which is the lowest level in the data hierarchy.*/

  // const leavesColumn = await findLowestLevel(data);

  for (let [i, column] of canonicalData.entries()) {
    // if (i === leavesColumn) {
    parseDuplicates(column, (newColumn) => {
      canonicalData.splice(i, 1, newColumn);
    });
    // }
  }
  return canonicalData;
}

const parseDuplicates = async (column, callback) => {
  let columnArr = column;
  for (let [i, obj] of columnArr.entries()) {
    let currentItem = columnArr[i];
    let nextItem = columnArr[i + 1];

    if (
      currentItem &&
      nextItem &&
      currentItem.body &&
      nextItem.body &&
      nextItem.body === currentItem.body
    ) {
      columnArr[i].coords = [
        columnArr[i].coords[0],
        columnArr[i + 1].coords[1],
      ];
      columnArr.splice(i + 1, 1);
      parseDuplicates(columnArr, () => {});
    } else {
      callback(columnArr);
    }
  }
};

export const populateCanonicalArrayCoords = (
  canonicalData,
  rowHeightOffset
) => {
  let leafCursor = 2 + rowHeightOffset;
  let newData = canonicalData;

  for (let [i, array] of canonicalData.entries()) {
    let cursor = 2 + rowHeightOffset;

    for (let [x, obj] of array.entries()) {
      const objRowSize = findObjRowSize(obj);

      if (objRowSize === 0) {
        const objCoords = [leafCursor, leafCursor + 1];
        leafCursor = leafCursor + 1;
        const updatedObj = { ...newData[i][x] };
        updatedObj.coords = objCoords;
        newData[i].splice(x, 1, updatedObj);
      } else {
        const objCoords = [cursor, cursor + objRowSize];
        cursor = cursor + objRowSize;
        const updatedObj = { ...newData[i][x] };
        updatedObj.coords = objCoords;
        newData[i].splice(x, 1, updatedObj);
      }
    }
  }

  return newData;
};

export const populateCanonicalDataArray = (objArray, canonicalData) => {
  const data = canonicalData;

  const parseObjects = (objArray) => {
    objArray.forEach((obj) => {
      if (obj.level || obj.level === 0) {
        data[obj.level].push(obj);
      }
      if (obj.children) parseObjects(obj.children);
    });
  };

  parseObjects(objArray);
  return data;
};

export const parseNonTreeData = (objArray) => {
  const tempData = [];

  objArray.forEach((obj) => {
    if (!obj.children) {
      tempData.push(obj);
    }
  });

  return tempData;
};

export const populateNonTreeCanonicalData = (
  canonicalData,
  nonTreeDataArr,
  rowOffset
) => {
  let xCoord = rowOffset;
  if (rowOffset === 0) xCoord = 2;

  nonTreeDataArr.forEach((obj) => {
    const updatedObj = {
      ...obj,
      coords: [xCoord, findRowHeight(canonicalData) + rowOffset],
    };
    canonicalData.push([updatedObj]);
  });

  return canonicalData;
};

export const findRowHeight = (canonicalData) => {
  let rowHeight = 1;

  canonicalData.forEach((columnArr) => {
    if (columnArr.length > rowHeight) rowHeight = columnArr.length;
  });

  // accouting for title row and offset by one.
  return rowHeight + 2;
};

export const genCanonicalDataArray = (level) => {
  const canonicalArr = [];

  for (let i = 0; i <= level; i++) {
    canonicalArr.push([]);
  }

  return canonicalArr;
};

export const findRowCellHeight = (canonicalData, rowOffset) => {
  let longestColumnLength = canonicalData.length;

  for (let columnArr of canonicalData) {
    let length = columnArr.length;
    if (length > longestColumnLength) longestColumnLength = length;
  }

  if (rowOffset == 0) return longestColumnLength + 2;
  return longestColumnLength + 1;
};

export const findChartHeight = (canonicalData) => {
  let height = 0;
  canonicalData.forEach((row) => {
    height = height + findRowCellHeight(row);
  });
  return height;
};

export const findColumns = (apiData) => {
  const keys = Object.keys(apiData);
  let overallLength = 0;

  for (let key of keys) {
    let length = 0;
    for (let obj of apiData[key]) {
      if (obj.children && obj.children.length > 0) {
        const lowestLevel = findLowestLevel(obj.children);
        length = length + lowestLevel;
      } else {
        length = length + 1;
      }
    }
    overallLength = length;
  }

  return overallLength;
};

// refactor to take lowest level of an object instead? if no children, return 1.
// Actually, we're expecting this to return 0 elsewhere in the application.
export const findLowestLevel = (objArray) => {
  let lowestLevel = 0;

  const parseHierarchy = (objArray) => {
    objArray.forEach((obj) => {
      if (obj.level > lowestLevel) lowestLevel = obj.level;
      if (obj.children && obj.children.length > 0) {
        parseHierarchy(obj.children);
      }
    });
  };

  parseHierarchy(objArray);
  return lowestLevel;
};

export const parseCitations = (objArray) => {
  const citationIds = [];
  const keys = Object.keys(objArray);

  const parseHierarchy = (objArray) => {
    objArray.forEach((obj) => {
      if (obj.body) {
        const text = obj.body;
        const splitArr = text.split("[");
        if (splitArr.length > 1) {
          const potentialKeyword = splitArr[1].split("]");

          if (potentialKeyword[0].length >= 20) {
            citationIds.push(potentialKeyword[0]);
          }
        }
      }
      if (obj.children && obj.children.length > 0) {
        parseHierarchy(obj.children);
      }
      return;
    });
  };

  for (let key of keys) {
    parseHierarchy(objArray[key]);
  }

  return citationIds;
};

export const findCategories = (apiData) => {
  let categories = [];
  const states = Object.keys(apiData);

  const parseHierarchy = (objArray) => {
    objArray.forEach((obj) => {
      if (categories.indexOf(obj.type) === -1) {
        categories.push(obj.type);
      }

      if (obj.children && obj.children.length > 0) {
        return parseHierarchy(obj.children);
      }
    });
  };

  for (let state of states) {
    parseHierarchy(apiData[state]);
  }

  for (let [i, category] of categories.entries()) {
    if (category === "State") {
      categories.splice(i, 1);
    }
  }

  return categories;
};

const findCategoryLevel = (category, apiData) => {
  let level = 0;
  const states = Object.keys(apiData);

  const parseHierarchy = (objArray) => {
    objArray.forEach((obj) => {
      if (obj.type === category) {
        level = obj.level;
      }

      if (obj.children && obj.children.length > 0) {
        return parseHierarchy(obj.children);
      }
    });
  };

  states.forEach((state) => {
    parseHierarchy(apiData[state]);
  });
  return level;
};

export const genCategoryLevelMapObject = (categories, apiData) => {
  const categoryLevelObj = {};

  for (let [i, category] of categories.entries()) {
    const level = findCategoryLevel(category, apiData);
    categoryLevelObj[category] = level;
  }

  return categoryLevelObj;
};

export const updateObjectByID = (id, key, value, apiData) => {
  let newData = apiData;
  const states = Object.keys(newData);

  const parseHierarchy = (objArray) => {
    objArray.forEach((obj) => {
      if (obj.id === id) {
        obj[key] = value;
        console.log("obj[key]", obj[key]);
      }

      if (obj.children && obj.children.length > 0) {
        return parseHierarchy(obj.children);
      }
    });
  };

  states.forEach((state) => {
    parseHierarchy(apiData[state]);
  });

  return newData;
};

export const replaceObjectByID = (id, updatedObj, data) => {
  const chartData = JSON.parse(JSON.stringify(data));
  const states = Object.keys(chartData);

  const parseHierarchy = (objArray) => {
    objArray.forEach((obj, i) => {
      if (obj.id === id) {
        objArray[i] = updatedObj;
      }

      if (obj.children && obj.children.length > 0) {
        return parseHierarchy(obj.children);
      }
    });
  };

  states.forEach((state) => {
    parseHierarchy(chartData[state]);
  });

  return chartData;
};

export const removeChildByIDs = (
  parentObjID,
  childObjID,
  apiData,
  maintainStructure = true
) => {
  let data = apiData;
  const states = Object.keys(apiData);
  const categories = findCategories(apiData);

  const parseHierarchy = (objArray, parentObjID, childObjID) => {
    objArray.forEach((obj) => {
      // Special case for deleting the entire state.
      if (obj.id === childObjID && obj.level === 0 && obj.type === "State") {
        let stateName = obj.body;
        let { [stateName]: deletedState, ...rest } = data;
        data = rest;
      }

      if (obj.id === parentObjID) {
        const lowestLevel = findLowestLevel(apiData[states[0]]);
        const newChildren = obj.children;
        let onlyChild = false;

        if (obj.children.length === 1) onlyChild = true;

        newChildren.forEach((childObj, i) => {
          if (childObj.id === childObjID) {
            if (onlyChild && maintainStructure) {
              const emptyTreeStructure = populateTreeStructure(
                childObj.level,
                lowestLevel,
                categories,
                childObj.parent,
                false
              );
              newChildren.push(emptyTreeStructure);
            }
            newChildren.splice(i, 1);
          }
        });

        obj.children = newChildren;
      }

      if (obj.children && obj.children.length > 0) {
        return parseHierarchy(obj.children, parentObjID, childObjID);
      }
    });
  };

  states.forEach((state) => {
    parseHierarchy(data[state], parentObjID, childObjID);
  });

  return data;
};

export const populateTreeStructure = (
  currentLevel,
  lowestLevel,
  categories,
  parentID,
  preview
) => {
  // can probably put a type lookup function in here. Actually yeah I think I have to.
  let categoryLevel = currentLevel - 1;
  if (categoryLevel < 0) categoryLevel = 0;

  let newID = nanoid();

  const newObj = {
    body: "",
    type: categories[categoryLevel],
    id: newID,
    level: currentLevel,
    parent: parentID,
    preview: preview,
  };

  if (preview) newObj.previewType = "add";

  if (currentLevel === lowestLevel) return newObj;
  else {
    const newChildren = [];
    newChildren.push(
      populateTreeStructure(
        currentLevel + 1,
        lowestLevel,
        categories,
        newID,
        preview
      )
    );
    newObj.children = newChildren;
    return newObj;
  }
};

export const genCanonicalData = (apiData, editMode) => {
  const states = Object.keys(apiData);
  const canonicalData = [];
  let rowOffset = 0;

  for (let state of states) {
    const stateArr = apiData[state];
    parseCitations(apiData);
    const lowestLevel = findLowestLevel(stateArr);
    const nonTreeDataArr = parseNonTreeData(stateArr);
    let canonicalRowData = genCanonicalDataArray(lowestLevel);
    canonicalRowData = populateCanonicalDataArray(stateArr, canonicalRowData);
    canonicalRowData = populateCanonicalArrayCoords(
      canonicalRowData,
      rowOffset
    );
    canonicalRowData = populateNonTreeCanonicalData(
      canonicalRowData,
      nonTreeDataArr,
      rowOffset
    );
    if (!editMode) {
      canonicalRowData = parseDuplicateCellData(canonicalRowData, stateArr);
    }
    const rowHeight = findRowCellHeight(canonicalRowData, rowOffset);
    rowOffset = rowOffset + rowHeight;
    canonicalData.push(canonicalRowData);
  }
  return canonicalData;
};

export const checkCitation = (citations, text) => {
  const ids = Object.keys(citations);
  let newStr = text;

  for (let id of ids) {
    if (newStr.search(id) !== -1) {
      newStr = newStr.replace(id, ids.indexOf(id) + 1);
    }
  }
  return newStr;
};

export const updateChildrenByParentID = (apiData, parentID, newChildren) => {
  const data = apiData;
  const states = Object.keys(data);

  const parseHierarchy = (objArray) => {
    objArray.forEach((obj) => {
      if (obj.id === parentID) {
        obj.children = Array.from(newChildren);
      }

      if (obj.children && obj.children.length > 0) {
        return parseHierarchy(obj.children);
      }
    });
  };

  states.forEach((state) => {
    parseHierarchy(data[state]);
  });

  return data;
};

export const addAColumn = (columnName, apiData) => {
  const data = apiData;
  const states = Object.keys(data);
  const lowestLevel = findLowestLevel(apiData[states[0]]);

  const parseHierarchy = (objArray) => {
    objArray.forEach((obj) => {
      if (obj.level === lowestLevel) {
        const newChild = {
          type: columnName,
          body: "",
          id: nanoid(),
          parent: obj.id,
          level: obj.level + 1,
        };
        obj.children = [newChild];
      }

      if (obj.children && obj.children.length > 0) {
        return parseHierarchy(obj.children);
      }
    });
  };

  states.forEach((state) => {
    parseHierarchy(data[state]);
  });

  return data;
};

export const removeLastColumn = (apiData) => {
  let data = apiData;
  const states = Object.keys(data);
  const lowestLevel = findLowestLevel(data[states[0]]);

  const parseHierarchy = (objArray) => {
    objArray.forEach((obj) => {
      if (obj.level === lowestLevel) {
        data = removeChildByIDs(obj.parent, obj.id, data, false);
      }

      if (obj.children && obj.children.length > 0) {
        return parseHierarchy(obj.children);
      }
    });
  };

  states.forEach((state) => {
    parseHierarchy(data[state]);
  });

  return data;
};

export const addCellToParent = (parentID, apiData) => {
  const data = apiData;
  const states = Object.keys(data);
  const categories = findCategories(data);
  const lowestLevel = findLowestLevel(data[states[0]]);

  const parseHierarchy = (objArray) => {
    objArray.forEach((obj) => {
      if (obj.id === parentID) {
        const newObj = populateTreeStructure(
          obj.level + 1,
          lowestLevel,
          categories,
          obj.id,
          false
        );

        obj.children = [...obj.children, newObj];
      }

      if (obj.children && obj.children.length > 0) {
        return parseHierarchy(obj.children);
      }
    });
  };

  states.forEach((state) => {
    parseHierarchy(data[state]);
  });

  return data;
};

export const addCitation = (citationObj, value) => {
  const newCitationObj = { ...citationObj };
  newCitationObj[nanoid()] = value;
  return newCitationObj;
};

export const deleteCitation = (citationObj, id) => {
  const { [id]: deletedCitation, ...rest } = citationObj;
  const newCitationObj = { ...rest };
  return newCitationObj;
};

export const updateCitation = (citationObj, id, value) => {
  const newCitationObj = { ...citationObj };
  newCitationObj[id] = value;
  return newCitationObj;
};

const findNextCategoryChildren = (children, categoryTarget) => {
  const nextChildren = [];

  const parseHierarchy = (objArray) => {
    objArray.forEach((obj) => {
      if (obj.type === categoryTarget) {
        const newObj = JSON.parse(JSON.stringify(obj));
        nextChildren.push(newObj);
      }

      if (obj.type !== categoryTarget && obj.children) {
        return parseHierarchy(obj.children, categoryTarget);
      }
    });
  };

  parseHierarchy(children);
  return nextChildren;
};

const createNewHierarchy = (categories, objArray) => {
  const newHierarchy = JSON.parse(JSON.stringify(objArray));

  const parseHierarchy = (objArray) => {
    objArray.forEach((obj, i) => {
      const categoryIndex = categories.indexOf(obj.type);
      const isCategory = categoryIndex !== -1;
      const isLastCategory = categoryIndex === categories.length - 1;

      if (isCategory && !isLastCategory) {
        const newChildren = JSON.parse(JSON.stringify(obj.children));

        obj.children = findNextCategoryChildren(
          newChildren,
          categories[categoryIndex + 1]
        );
      }

      if (isCategory && isLastCategory) {
        const { children, ...rest } = obj;
        obj.children = "";
      }

      if (obj.children && obj.children.length > 0) {
        return parseHierarchy(obj.children);
      }
    });
  };

  parseHierarchy(newHierarchy);
  return newHierarchy;
};

const constructState = (categories, stateData) => {
  const { children, ...rest } = stateData[0];

  let newObj = {
    ...rest,
    children: createNewHierarchy(categories, stateData[0].children),
  };

  newObj = parseNewHierarchyLevels([newObj]);

  console.log(newObj);
  return newObj;
};

const parseNewHierarchyLevels = (stateData) => {
  const newData = JSON.parse(JSON.stringify(stateData));

  const parseHierarchy = (objArray, level) => {
    objArray.forEach((obj) => {
      obj.level = level;

      if (obj.children && obj.children.length > 0) {
        return parseHierarchy(obj.children, level + 1);
      }
    });
  };
  parseHierarchy(newData, 0);
  return newData;
};

const constructNewChart = (states, categories, apiData) => {
  const newChart = {};

  states.forEach((state) => {
    const stateData = apiData[state];
    const newState = constructState(categories, stateData);
    newChart[state] = newState;
  });

  return newChart;
};

const findObjByID = (id, data) => {
  const chartData = JSON.parse(JSON.stringify(data));
  const states = Object.keys(chartData);
  let match;

  const parseHierarchy = (objArray) => {
    objArray.forEach((obj) => {
      if (obj.id === id) match = obj;

      if (obj.children && obj.children.length > 0) {
        return parseHierarchy(obj.children);
      }
    });
  };

  states.forEach((state) => {
    parseHierarchy(chartData[state]);
  });

  if (!match) throw new Error("Unable to locate object by id.");
  return JSON.parse(JSON.stringify(match));
};

const markObjectsForDeletion = (objArray) => {
  const parseHierarchy = (objArray) => {
    objArray.forEach((obj) => {
      obj.preview = true;
      obj.previewType = "delete";

      if (obj.children && obj.children.length > 0) {
        return parseHierarchy(obj.children);
      }
    });
  };

  parseHierarchy(objArray);
  return objArray;
};

export const createPreviewChartData = (chartData, preview) => {
  // Where chartData is the previous version in the version history's data.
  // Where preview is the preview object for the current version in the version history.

  if (preview.type === "update") {
    const data = JSON.parse(JSON.stringify(chartData));
    const { id, key, value } = preview.params;
    const obj = findObjByID(id, data);

    const oldText = obj[key];
    const newText = value;

    const diff = diffWords(oldText, newText);

    obj.preview = true;
    obj.previewType = "update";
    obj.diff = diff;

    const newData = replaceObjectByID(id, obj, data);
    return newData;
  }

  if (preview.type === "add") {
    const data = JSON.parse(JSON.stringify(chartData));
    const { parentID } = preview.params;
    const states = Object.keys(data);
    const lowestLevel = findLowestLevel(data[states[0]]);
    const categories = findCategories(data);
    const parentObj = findObjByID(parentID, data);

    const newTreeStructure = populateTreeStructure(
      parentObj.level + 1,
      lowestLevel,
      categories,
      parentObj.id,
      true
    );

    parentObj.children.push(newTreeStructure);
    const newData = replaceObjectByID(parentID, parentObj, data);
    return newData;
  }

  if (preview.type === "delete") {
    const data = JSON.parse(JSON.stringify(chartData));
    const { id } = preview.params;
    const obj = findObjByID(id, data);

    const newObjArr = markObjectsForDeletion([obj]);
    const newData = replaceObjectByID(id, newObjArr[0], data);
    return newData;
  }
};

import { BACKEND_URL } from "API_ENDPOINT";

export const FETCH_PENDING_ACRONYMS = async () => {
  const result = await fetch(`${BACKEND_URL}/pendingAcronyms`);
  const json = await result.json();

  if (json.pendingAcronyms) return json.pendingAcronyms;
};

export const UPDATE_PENDING_ACRONYM = async (acronym) => {
  const result = await fetch(`${BACKEND_URL}/updateEditedAcronym`, {
    method: "POST",
    body: JSON.stringify(acronym),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await result.json();
  return json.acceptConfirmed;
};

export const REJECT_PENDING_ACRONYM = async (acronym) => {
  const result = await fetch(`${BACKEND_URL}/rejectPendingAcronym`, {
    method: "POST",
    body: JSON.stringify(acronym),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const json = await result.json();
  return json.deletionConfirmed;
};

export const ACCEPT_PENDING_ACRONYM = async (acronym) => {
  const result = await fetch(`${BACKEND_URL}/acceptPendingAcronym`, {
    method: "POST",
    body: JSON.stringify(acronym),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await result.json();
  return json.acceptConfirmed;
};

export const CHECK_TOKEN = async () => {
  const token = JSON.parse(localStorage.getItem("token"));

  if (!token) return false;

  const payload = { token: token };

  const result = await fetch(`${BACKEND_URL}/user/validate`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await result.json();
  return json.admin;
};

export const LOGIN = async (formData) => {
  const result = await fetch(`${BACKEND_URL}/admin/login`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(formData),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const json = await result.json();
  return json;
};

export const CHECK_ADMIN = async () => {
  const token = JSON.parse(localStorage.getItem("token"));

  if (!token) return false;

  const payload = { token: token };

  const result = await fetch(`${BACKEND_URL}/admin/validate`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await result.json();
  return json.admin;
};

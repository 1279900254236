import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

export default function Cell(props) {
  const { coords, name, type, body, column, isTitle } = props;
  const containerRef = useRef();

  return (
    <Container
      ref={containerRef}
      coords={coords}
      isTitle={isTitle}
      column={column}
    >
      <FixedCell>
        <Text>{name || body}</Text>
      </FixedCell>
    </Container>
  );
}

const Text = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.md};
  margin: 0px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  grid-row: 1 / 2;
  flex-direction: row;
  border-bottom: 2px solid black;
  align-items: center;
  position: sticky;
  top: 55px;
`;

const FixedCell = styled.div.attrs(({ scrollY }) => ({
  style: {
    backgroundColor: `${scrollY > 0 ? "white" : "#f7f7f2"}`,
    boxShadow: `${scrollY > 0 ? "1px 2px 1px gray" : ""}`,
    borderLeft: `${scrollY > 0 ? "1px solid black" : ""}`,
    borderRight: `${scrollY > 0 ? "1px solid black" : ""}`,
  },
}))`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.offWhite};
  background-color: white;
  box-sizing: border-box;
`;

import React from "react";
import styled from "styled-components";

import { DELETE_CHART } from "./API";

export default function DeleteControls(props) {
  const { toggleDelete, id, fetchData } = props;

  async function handleDelete() {
    await DELETE_CHART(id);
    await fetchData();
  }

  return (
    <Container>
      <Message>Permanently delete this chart?</Message>
      <Controls>
        <CancelButton onClick={() => toggleDelete(false)}>Cancel</CancelButton>
        <ConfirmButton onClick={handleDelete}>Delete</ConfirmButton>
      </Controls>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
`;

const Message = styled.div`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.sm};
`;

const Button = styled.button`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xxs};
  color: #2e2e2e;
  letter-spacing: 0.02rem;
  font-weight: bold;
  border: none;
  border-radius: 2px;
  padding: 5px 18px 5px 18px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.offWhite};
  box-shadow: 1px 2px 3px 0px gray;

  &:hover {
    transform: translateY(-1px);
  }
`;

const CancelButton = styled(Button)`
  outline: 2px solid gray;
  margin-right: 30px;
  &:hover {
    outline: 2px solid ${({ theme }) => theme.colors.mainGold};
    background-color: ${({ theme }) => theme.colors.mainGold};
    color: white;
    transform: translateY(-1px);
  }
`;

const ConfirmButton = styled(Button)`
  outline: 2px solid red;
  margin-left: 30px;
  &:hover {
    background-color: red;
    color: white;
    transform: translateY(-1px);
  }
`;

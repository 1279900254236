import React from "react";
import styled from "styled-components";

import { Title } from "components/Title";
import Dropdown from "./Dropdown";
import Arrow from "./Arrow.icon";

export default function EditCriteriaForm(props) {
  // methods that call util funcs to edit the data and then
  // passes the resulting chart obj into this.updateSample

  // const data = props.sample["Arizona"][0];
  // console.log("data", data);
  const data = props.sample;
  const keys = Object.keys(data);

  const { selected, updateSelected, toggleCreateForm } = props;

  return (
    <Card>
      <BackButton onClick={toggleCreateForm}>
        <Arrow />
      </BackButton>
      <Container>
        <Title size="lg" alignTitle="left" spanWidth="100%">
          Edit State Criteria
        </Title>
        <Content>
          {keys.map((key) => {
            return (
              <Dropdown
                key={key}
                data={data[key][0]}
                selected={selected}
                updateSelected={updateSelected}
              />
            );
          })}
        </Content>
        <Title
          size="lg"
          alignTitle="left"
          spanWidth="100%"
          styles="margin-bottom: 30px;"
        >
          Edit Categories
        </Title>
        <CreateButton onClick={() => {}}>+ Create new chart</CreateButton>
      </Container>
    </Card>
  );
}
const BackButton = styled.button`
  margin-top: 25px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-weight: normal;
  background-color: transparent;
  font-size: 22px;
  position: absolute;
  cursor: pointer;
`;

const CreateButton = styled.div`
  height: 45px;
  width: 100%;
  font-weight: bold;
  border: 3px solid ${({ theme }) => theme.colors.mainGold};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.mainGold};
  cursor: pointer;
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  margin-bottom: 10px;
  color: #262626;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.mainGold};
    box-shadow: 1px 1px 1px 1px lightgray;
    transform: translateY(-1px);
  }
`;

const Content = styled.div`
  padding: 10px 0px 0px 0px;
  margin: 5px 0px 20px 0px;
  min-width: 540px;
  max-height: 500px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Container = styled.div`
  margin: 0px 20px 0px 20px;
  padding: 15px 20px 15px 20px;
`;

const Card = styled.div`
  box-sizing: border-box;
  padding: 5px 20px 10px 20px;
  box-shadow: 2px 2px 4px 1px lightgray;
  background-color: white;
  border-radius: 5px;
  z-index: 2;
`;

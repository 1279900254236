import React from "react";

export const CopyIcon = (props) => {
  const { isCopied } = props;

  if (!isCopied) {
    return (
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.75"
          y="0.75"
          width="14.6538"
          height="14.8333"
          rx="3.25"
          fill="white"
          stroke="black"
          strokeWidth="1.5"
        />
        <rect
          x="5.5957"
          y="5.41663"
          width="14.6538"
          height="14.8333"
          rx="3.25"
          fill="white"
          stroke="black"
          strokeWidth="1.5"
        />
      </svg>
    );
  }
  if (isCopied) {
    return (
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="11.5" cy="11.5" r="11.5" fill="#14B224" />
        <path d="M6 11.5L10 15.5L17.5 8" stroke="white" strokeWidth="2" />
      </svg>
    );
  }
};

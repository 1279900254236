import React from "react";
import styled from "styled-components";

import DeleteIcon from "../cell/DeleteIcon";

export function DeleteButton(props) {
  const { handleClick, show } = props;

  return (
    <Container show={true}>
      <DeleteIcon handleClick={handleClick} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  flex-direction: row;
  width: 100%;
  height: 50px;
  justify-content: flex-end;
  align-items: center;
`;

import React, { useState } from "react";
import styled from "styled-components";
import chevron from "./chevron_black.png";

export default function DMARCDetails(props) {
  const { text, records } = props.record;
  const [expanded, setExpanded] = useState(false);
  const { tags } = records;

  /* 
    tags: {
      p: {value: reject, description: "DMARC Policy"}
      rua: {value: mailto:gmail.com, description: ""}
    } 
  */

  const Table = (props) => {
    if (!props.tags) return null;
    const keys = Object.keys(tags);

    const descriptionOverrides = {
      p: `Policy requesting mail reciever's server to take specified action when mail spoofing your domain is recieved. Can be "none", "quarantine", or "reject". Using "none" allows monitoring without policy enforcement. `,
      sp: "Policy for subdomains. See p.",
      adkim: `DKIM Alignment mode. Can be strict "s" or relaxed "r". Relaxed allows subdomains to pass.`,
      rua: `Identifies an email address to send aggregate feedback.`,
      ruf: `Identifies and email address to send forensic monitoring feedback.`,
      pct: "Percentage of emails that DMARC policy is applied to.",
      aspf: `SPF Alignment mode. Can be strict "s" or relaxed "r". Relaxed allows subdomains to pass.`,
      fo: `Forensic Reporting Options. How forensic reports are created and presented to DMARC users.`,
      rf: `Forensic Reporting Format. The forensic reporting format.`,
      ri: "Forensic Reporting Interval. Corresponds to the aggregate reporting interval, provides DMARC feedback for outlined criteria.",
    };

    const overrideKeys = Object.keys(descriptionOverrides);

    // adds all DMARC tags to returned parsed tags.
    overrideKeys.forEach((overrideKey) => {
      if (!keys.includes(overrideKey)) {
        tags[overrideKey] = { value: "", description: "" };
      }
    });

    // overrides descriptions returned with parsed tags with our descriptions (defined above)
    keys.forEach((key) => {
      if (overrideKeys.includes(key)) {
        tags[key].description = descriptionOverrides[key];
      }
    });

    return (
      <TableContainer>
        <RecordTable>
          <tbody>
            <Row hide={!tags}>
              <TitleCell>Tag</TitleCell>
              <TitleCell>Value</TitleCell>
              <TitleCell left>Description</TitleCell>
            </Row>
            {keys.map((key) => {
              return (
                <Row key={key}>
                  <KeyCell>{key}</KeyCell>
                  <ValueCell value={tags[key].value}>
                    {tags[key].value === "" ? "Not set." : tags[key].value}
                  </ValueCell>
                  <DescriptionCell left>
                    {tags[key].description}
                  </DescriptionCell>
                </Row>
              );
            })}
          </tbody>
        </RecordTable>
      </TableContainer>
    );
  };

  return (
    <Container>
      <Button onClick={() => setExpanded(!expanded)}>
        <Chevron expanded={expanded} src={chevron} alt={"dropdown chevron"} />
        {expanded ? "Collapse Details" : "View Details"}
      </Button>
      <Content expanded={expanded}>
        <Title>Record Text:</Title>
        <Text>{text || "No DMARC Record configured."}</Text>
        <Table tags={tags} />
      </Content>
    </Container>
  );
}

const TableContainer = styled.div`
  width: 100%;
  padding: 3px;
  box-sizing: border-box;
  overflow-x: auto;
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.sm};
  display: ${({ hide }) => (hide ? "none" : "block")};

  @media (max-width: 800px) {
    font-size: ${({ theme }) => theme.text.xs};
  }
`;

const RecordTable = styled.table`
  width: 100%;
  margin-left: -5px;
`;

const Title = styled(Text)`
  font-weight: bold;
  margin-top: 25px;
`;

const Content = styled.div`
  display: ${({ expanded }) => (expanded ? "block" : "none")};
`;

const Container = styled.div``;

const Button = styled.button`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xxs};
  outline: none;
  border: none;
  background-color: #e7e7e7;

  cursor: pointer;
  box-sizing: border-box;
  padding: 7px 10px 7px 5px;
  border-radius: 2px;
  margin-top: 5px;
  transition: translate box-shadow 100ms linear;

  &:hover {
    transition: translate box-shadow 100ms linear;
    transform: translateY(-1px);
    box-shadow: 1px 1px 2px 0px #636363;
  }
`;

const Chevron = styled.img`
  margin-bottom: ${({ expanded }) => (expanded ? "-1px" : "-2px")};
  margin-right: 3px;
  transform: ${(props) =>
    props.expanded ? "rotate(180deg) scale(0.75)" : "scale(0.75)"};
`;

const Row = styled.tr`
  display: ${({ hide }) => (hide ? "none" : "auto")};
`;

const Cell = styled.td`
  text-align: ${({ left }) => (left ? "left" : "center")};
  font-family: ${({ theme }) => theme.textFont};
  border: 2px solid black;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 24px;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    font-size: ${({ theme }) => theme.text.xs};
  }

  @media (max-width: 800px) {
    font-size: ${({ theme }) => theme.text.xxs};
  }
`;

const KeyCell = styled(Cell)`
  height: 30px;
  width: 70px;
`;

const ValueCell = styled(Cell)`
  height: 30px;
  padding: 0px 30px 0px 30px;
  font-style: ${({ value }) => (value === "" ? "italic" : "normal")};
  overflow-wrap: break-word;
  max-width: 350px;

  @media (max-width: 800px) {
    padding: 0px 10px 0px 10px;
    max-width: 150px;
  }
`;

const DescriptionCell = styled(Cell)`
  height: 30px;
  padding-left: 15px;
  padding-right: 10px;

  @media (max-width: 800px) {
    line-height: 22px;
    font-size: ${({ theme }) => theme.text.xxs};
  }
`;

const TitleCell = styled(Cell)`
  font-weight: bold;
  padding-left: ${({ left }) => (left ? "15px" : "0px")};
  background-color: ${({ theme }) => theme.colors.darkBlue};
  color: white;
`;

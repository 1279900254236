import React, { useContext } from "react";
import styled from "styled-components";

export const Controls = (props) => {
  const { id, editMode, toggleEditMode, handleSave, handleDiscard } = props;

  return (
    <Container show={editMode}>
      <EditButton show={!editMode} onClick={toggleEditMode}>
        Edit Citation
      </EditButton>
      <DiscardButton show={editMode} onClick={handleDiscard}>
        Discard Changes
      </DiscardButton>
      <SaveButton show={editMode} onClick={handleSave}>
        Save Changes
      </SaveButton>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 5px;
  display: ${({ show }) => (show ? "auto" : "none")};
  display: flex;
  flex-direction: row;
`;

const EditButton = styled.div`
  display: ${({ show }) => (show ? "auto" : "none")};
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xxs};
  outline: 2px solid ${({ theme }) => theme.colors.mainGold};
  color: #2e2e2e;
  letter-spacing: 0.03rem;
  font-weight: bold;
  border: none;
  border-radius: 2px;
  padding: 5px 18px 5px 18px;
  cursor: pointer;
  background-color: white;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 1px 2px 3px 0px gray;
    background-color: ${({ theme }) => theme.colors.mainGold};
    color: white;
  }
`;

const DiscardButton = styled(EditButton)`
  outline: 2px solid gray;
  background-color: gray;
  color: white;

  &:hover {
    box-shadow: 1px 2px 3px 0px gray;
    background-color: gray;
  }
`;

const SaveButton = styled(EditButton)`
  outline: 2px solid green;

  &:hover {
    background-color: green;
  }

  margin-left: 20px;
`;

import { BACKEND_URL } from "API_ENDPOINT";

export const GET_MASTER_METADATA = async () => {
  const response = await fetch(`${BACKEND_URL}/charts/metadata`);
  const metaData = await response.json();
  return metaData;
};

export const CREATE_NEW_CHART = async (states, categories, title, token) => {
  const response = await fetch(`${BACKEND_URL}/charts/construct`, {
    method: "POST",
    body: JSON.stringify({
      states: states,
      categories: categories,
      title: title,
      token: token,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
};

export const GET_CHARTS_METADATA = async (token) => {
  const response = await fetch(`${BACKEND_URL}/charts`, {
    method: "POST",
    body: JSON.stringify({ token: token }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  console.log("json.data", json.data);
  return json.data;
};

export const CHECK_TOKEN = async () => {
  const token = JSON.parse(localStorage.getItem("token"));

  if (!token) return false;

  const payload = { token: token };

  const result = await fetch(`${BACKEND_URL}/user/validate`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await result.json();
  return json.validToken;
};

export const LOGIN = async (formData) => {
  const result = await fetch(`${BACKEND_URL}/user/login`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(formData),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const json = await result.json();
  return json;
};

export const GET_USERNAME = async () => {
  const token = JSON.parse(localStorage.getItem("token"));

  const payload = { token: token };

  const result = await fetch(`${BACKEND_URL}/user/name`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const json = await result.json();
  return json.userName;
};

export const GET_SAMPLE = async (states, categories) => {
  const result = await fetch(`${BACKEND_URL}/charts/construct/sample`, {
    method: "POST",
    body: JSON.stringify({ states, categories }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const json = await result.json();
  return json.sample;
};

export const DELETE_CHART = async (id) => {
  const result = await fetch(`${BACKEND_URL}/charts/delete`, {
    method: "POST",
    body: JSON.stringify({ id: id }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = result.json();
  return json.accepted;
};

import React, { useState } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";

import DeleteIcon from "./DeleteIcon";
import DeleteControls from "./DeleteControls";

export default function ChartButton(props) {
  const { title, id, fetchData } = props;
  const [redirect, setRedirect] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  function handleRedirect() {
    setRedirect(true);
  }

  function handleDelete(value) {
    setIsDeleting(value);
  }

  function toggleControls(evt) {
    evt.stopPropagation();
    setIsDeleting(true);
  }

  if (redirect) return <Redirect push to={`/charts/${id}`} />;

  if (!isDeleting) {
    return (
      <Button
        onClick={handleRedirect}
        onMouseOver={(evt) => {
          setIsHovered(true);
        }}
        onMouseOut={() => setIsHovered(false)}
      >
        <Title>{title}</Title>
        <DeleteIcon handleClick={toggleControls} isHovered={isHovered} />
      </Button>
    );
  }

  if (isDeleting) {
    return (
      <Button>
        <DeleteControls
          toggleDelete={handleDelete}
          id={id}
          fetchData={fetchData}
        />
      </Button>
    );
  }
}

const Button = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 20px 0px;
  min-width: 500px;
  padding: 20px;
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.sm};
  background-color: white;
  box-shadow: 1px 2px 3px 0px lightgray;
  border-radius: 3px;

  &:hover {
    box-shadow: 1px 2px 4px 1px lightgray;
  }
`;

const Title = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.md};
`;

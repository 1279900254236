import React from "react";
import styled from "styled-components";
import { Title } from "components/Title";
import LinkButton from "components/LinkButton";
import { CenterBlock } from "components/CenterBlock";
import { Subtext } from "components/Subtext";

export default function DNSCard() {
  return (
    <Container>
      <Image src="dns_icon.png" alt="An old fashioned U-bolt lock." />
      <Title size="lg" align="center" alignItems="center" spanColor="#e1a915">
        Domain Spoofing Check
      </Title>
      <CenterBlock>
        <Subtext
          size="xs"
          alignment="center"
          styles="margin-bottom: -10px; margin-top: -20px; max-width: 350px; line-height: 28px;"
        >
          Check your domain to verify it's configured to protect against email
          spoofing.
        </Subtext>
      </CenterBlock>
      <CenterBlock>
        <LinkButton to="/dns-lookup">Start</LinkButton>
      </CenterBlock>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 50px 30px 50px;
  background-color: white;
  border-radius: 3px;
  height: 380px;
  width: 340px;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.3);
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  margin: 30px;

  @media (max-width: 1800px) {
    height: 350px;
    width: 300px;
  }

  @media (max-width: 1350px) {
    margin-bottom: 5vh;
  }
`;

const Image = styled.img`
  align-self: center;
  margin-top: 10px;
  height: auto;

  @media (max-width: 1800px) {
    width: 100px;
  }
`;

import React from "react";
import styled from "styled-components";

export function TabSelect(props) {
  const { setTabSelected, tabSelected } = props;

  return (
    <Container>
      <Tab
        active={tabSelected === "graph"}
        onClick={() => setTabSelected("graph")}
      >
        <Circle active={tabSelected === "graph"} />
        <Text>Rate Graph</Text>
      </Tab>
      <Tab
        active={tabSelected === "calculator"}
        onClick={() => setTabSelected("calculator")}
      >
        <Circle active={tabSelected === "calculator"} />
        <Text>Spread Calculator</Text>
      </Tab>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  margin-bottom: 20px;
  user-select: none;
`;

const Circle = styled.div`
  width: 12px;
  height: 12px;
  margin: 2px 10px 0px 0px;
  border-radius: 50%;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.mainGold : "#e0e0dc"};
`;

const Tab = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) => (active ? "white" : "#fafafa")};
  text-align: center;
  margin: 5px 10px 5px 10px;
  padding: 8px 10px 8px 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 0px gray;
  cursor: pointer;

  &:hover {
    background-color: white;
    box-shadow: 1px 1px 1px 0px gray;
  }
`;

const Text = styled.p`
  margin: 0px;
  font-family: ${({ theme }) => theme.textFont};
`;

import React from "react";
import styled from "styled-components";

export default function Controls(props) {
  const { handleSave, handleDiscard, show } = props;

  return (
    <Container show={show}>
      <Button onClick={handleDiscard}>Discard</Button>
      <Button onClick={handleSave}>Save</Button>
    </Container>
  );
}

const Container = styled.div`
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const Button = styled.button`
  cursor: pointer;
`;

import React, { useContext } from "react";
import styled from "styled-components";

import Cell from "./cell/Cell";
import TitleCell from "./TitleCell";

import { nanoid } from "nanoid";
import { findChartHeight } from "./utils";

import DragDropContainer from "./DragDropContainer";
import { EditContext } from "./EditContext";

export default function Grid(props) {
  const { data, titleData, citations, updateChildren } = props;
  const context = useContext(EditContext);

  const mapDragCells = (canonicalData, citations) => {
    return canonicalData.map((row) => {
      return row.map((column) => {
        let matchingObjects = [];
        return column.map((obj, i) => {
          let currentParent = "";
          let nextParent = undefined;

          if (obj && obj.hasOwnProperty("parent")) currentParent = obj.parent;

          if (column[i + 1] && column[i + 1].hasOwnProperty("parent"))
            nextParent = column[i + 1].parent;

          if (currentParent === nextParent) matchingObjects.push(obj);

          if (
            currentParent !== nextParent ||
            currentParent === "" ||
            nextParent === ""
          ) {
            const allMatchingObjects = matchingObjects;
            allMatchingObjects.push(obj);
            matchingObjects = [];
            return (
              <DragDropContainer
                coords={genDragCellCoordinates(allMatchingObjects)}
                children={allMatchingObjects}
                citations={citations}
                parentID={currentParent}
                updateChildren={updateChildren}
                key={nanoid()}
              />
            );
          }
        });
      });
    });
  };

  const genDragCellCoordinates = (allMatchingObjects) => {
    const coords = [];
    coords.push(allMatchingObjects[0].coords[0]);
    coords.push(allMatchingObjects[allMatchingObjects.length - 1].coords[1]);
    return coords;
  };

  if (data && citations) {
    return (
      <Container>
        <GridContent {...props}>
          {titleData.map((title, i) => {
            return (
              <TitleCell
                name={title}
                isTitle
                column={i}
                key={nanoid()}
                citations={citations}
              />
            );
          })}
          {!context.dragMode &&
            data.map((row) => {
              return row.map((columnArr, i) => {
                return columnArr.map((obj) => {
                  return (
                    <Cell
                      {...obj}
                      column={i}
                      key={nanoid()}
                      parent={obj.parent}
                      citations={citations}
                    />
                  );
                });
              });
            })}
          {context.dragMode && mapDragCells(data, citations)}
        </GridContent>
        <Placeholder show={context.versionControl} />
      </Container>
    );
  }
  return <p>Loading...</p>;
}

const genRows = (data) => {
  let str;
  const rows = findChartHeight(data);

  for (let i; i <= rows; i++) {
    str = str + "auto ";
  }
  return str;
};

const genColumns = (titleData) => {
  let str = "";
  for (let title of titleData) {
    str = str + "15vw ";
  }
  return str;
};

const Placeholder = styled.div`
  width: 300px;
  display: ${({ show }) => (show ? "block" : "none")};
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const GridContent = styled.div`
  margin-top: 25px;
  display: grid;
  grid-template-columns: ${({ titleData }) =>
    titleData ? genColumns(titleData) : ""};
  grid-template-rows: ${({ data }) => (data ? genRows(data) : "")};
  grid-template-areas: "header" "body";
`;

import React, { useEffect, useState } from "react";
import { GET_FIXED_DATA, GET_ADJUSTABLE_DATA } from "./API";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

export default function YieldGraph() {
  const [data, setData] = useState({ fixed: null, adjustable: null });

  useEffect(() => {
    async function getData() {
      const sampleData = [];

      let rawFixed = await GET_FIXED_DATA();
      rawFixed = rawFixed.split(`\n`);
      const fixedPeriods = rawFixed.slice(1, 52);

      fixedPeriods.forEach((str) => {
        const periods = str.split("|");
        const date = periods[0];

        sampleData.push({
          name: date,
          "30 year": periods[30],
          "15 year": periods[15],
        });
      });

      setData({ sampleData });
    }

    getData();
  }, []);

  return (
    <>
      <h3>Fixed Rate (1/2/2017 - 12/18/2017)</h3>
      <LineChart
        width={1400}
        height={600}
        data={data.sampleData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={[1.5, "dataMax + 1.5"]} />
        <Tooltip
          wrapperStyle={{
            border: "none",
            outline: "none",
            boxShadow: "1px 1px 1px gray",
          }}
          isAnimationActive={false}
        />
        <Legend />
        <Line
          type="monotone"
          dataKey="15 year"
          stroke="#8884d8"
          strokeWidth={3}
        />
        <Line
          type="monotone"
          dataKey="30 year"
          stroke="#82ca9d"
          strokeWidth={3}
        />
      </LineChart>
    </>
  );
}

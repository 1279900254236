import React from "react";
import styled from "styled-components";

export default function SearchBar(props) {
  const { query, updateQuery, search } = props;

  return (
    <Container>
      <MagnifyingGlass src="magnifying_glass.svg" alt="magnifying glass" />
      <Input
        onChange={(evt) => updateQuery(evt.target.value)}
        value={query}
        onKeyDown={(evt) => {
          if (evt.key === "Enter") search();
        }}
        placeholder="Enter a root domain (ex. mortgagebanking.law)"
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 700px;
  height: 45px;
  background-color: white;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  outline: none;
  box-sizing: border-box;
  padding-left: 30px;
  z-index: 1;

  @media (max-width: 1330px) {
    width: 600px;
  }

  @media (max-width: 600px) {
    width: 90vw;
  }
`;

const Input = styled.input`
  outline: none;
  border: none;
  width: 100%;
  background-color: transparent;

  font-size: ${(props) => props.theme.text.xs};
  font-family: ${(props) => props.theme.textFont};
  box-sizing: border-box;
  padding: 10px 10px 10px 40px;

  ::placeholder {
    color: gray;
  }

  display: ${(props) => (props.viewAllResults ? "none" : "inline")};

  transition: padding 10ms ease;

  @media (max-width: 600px) {
    padding: 0px 20px 0px 20px;
    padding: 10px;
  }
`;

const MagnifyingGlass = styled.img`
  display: ${(props) => (props.viewAllResults ? "none" : "inline")};

  @media (max-width: 600px) {
    display: none;
  }
`;

import React, { useState } from "react";
import styled from "styled-components";

export default function DeleteIcon(props) {
  const [color, setColor] = useState("gray");
  const { handleClick } = props;

  return (
    <Container
      onMouseEnter={() => setColor("red")}
      onMouseLeave={() => setColor("gray")}
      onClick={handleClick}
    >
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.000146024 6.72722C0.000146024 7.16126 0.172505 7.57743 0.479403 7.88436C0.786302 8.19129 1.20251 8.36362 1.6364 8.36362V16.5454C1.6364 17.4135 1.98126 18.2459 2.59506 18.8597C3.20873 19.4734 4.04127 19.8182 4.90916 19.8182H13.091C13.9589 19.8182 14.7914 19.4733 15.4051 18.8597C16.0189 18.2459 16.3637 17.4135 16.3637 16.5454V8.36362C16.7976 8.36362 17.2138 8.19126 17.5207 7.88436C17.8277 7.57746 18 7.16126 18 6.72722V5.09096C18 4.65693 17.8276 4.24075 17.5207 3.93382C17.2138 3.62689 16.7976 3.45456 16.3637 3.45456H13.0909V1.81816C13.0909 1.38412 12.9186 0.967948 12.6117 0.66102C12.3048 0.35426 11.8886 0.181763 11.4545 0.181763H6.54545C6.11142 0.181763 5.69524 0.354264 5.38831 0.66102C5.08141 0.967922 4.90905 1.38412 4.90905 1.81816V3.45456H1.63625C1.20236 3.45456 0.786185 3.62692 0.479257 3.93382C0.17233 4.24072 0 4.65693 0 5.09096L0.000146024 6.72722ZM14.7274 16.5454C14.7274 16.9794 14.5549 17.3956 14.2482 17.7025C13.9413 18.0094 13.5251 18.1818 13.091 18.1818H4.9092C4.47516 18.1818 4.05899 18.0094 3.75206 17.7025C3.4453 17.3956 3.2728 16.9794 3.2728 16.5454V8.36358H14.7273L14.7274 16.5454ZM6.5456 1.81813H11.4547V3.45453H6.5456V1.81813ZM1.63651 5.09093H16.3638V6.72733L1.63651 6.72718V5.09093Z"
          fill={color}
        />
        <path
          d="M5.72629 16.5455C5.94331 16.5455 6.15149 16.4593 6.30486 16.3059C6.45839 16.1524 6.54457 15.9443 6.54457 15.7273V10.8182C6.54457 10.526 6.38862 10.2559 6.13551 10.1097C5.88226 9.9636 5.57035 9.9636 5.31726 10.1097C5.06414 10.2559 4.9082 10.526 4.9082 10.8182V15.7273C4.9082 15.9443 4.99438 16.1524 5.1479 16.3059C5.30128 16.4593 5.50945 16.5455 5.72633 16.5455H5.72629Z"
          fill={color}
        />
        <path
          d="M8.99973 16.5455C9.21674 16.5455 9.42478 16.4593 9.5783 16.3059C9.73168 16.1524 9.81786 15.9443 9.81786 15.7273V10.8182C9.81786 10.526 9.66191 10.2559 9.4088 10.1097C9.15569 9.9636 8.84378 9.9636 8.5907 10.1097C8.33758 10.2559 8.18164 10.526 8.18164 10.8182V15.7273C8.18164 15.9443 8.26782 16.1524 8.4212 16.3059C8.57472 16.4593 8.78275 16.5455 8.99977 16.5455H8.99973Z"
          fill={color}
        />
        <path
          d="M12.2714 16.5455C12.4882 16.5455 12.6964 16.4593 12.8498 16.3059C13.0033 16.1524 13.0895 15.9443 13.0895 15.7273V10.8182C13.0895 10.526 12.9335 10.2559 12.6804 10.1097C12.4273 9.9636 12.1154 9.9636 11.8622 10.1097C11.6091 10.2559 11.4531 10.526 11.4531 10.8182V15.7273C11.4531 15.9443 11.5393 16.1524 11.6928 16.3059C11.8462 16.4593 12.0544 16.5455 12.2714 16.5455H12.2714Z"
          fill={color}
        />
      </svg>
    </Container>
  );
}

const Container = styled.div`
  padding: 0px;
  margin: 0px;
  height: 25px;
  cursor: pointer;
  &:hover {
    transform: translateY(-1px);
  }
`;

export const getAmendedWeeks = (weeks) => {
  const amendedWeeks = [];

  weeks.forEach((week) => {
    if (week.amended) {
      amendedWeeks.push(week);
    }
  });

  return amendedWeeks;
};

export const getParsedAmendedWeeks = (weeks) => {
  const amendedWeeks = [];

  weeks.forEach((week) => {
    if (week.amended) {
      amendedWeeks.push(week.date);
    }
  });

  console.log("amendedWeeks", amendedWeeks);
  return [...new Set(amendedWeeks)];
};

export const getRate = (period, rates) => {
  /* period: str. ex "2 year"
     rates: array. Ex ["3.83", "4.81", ...]
  */
  let periodAsInteger = parseInt(period.split(" ")[0]);
  let periodIndex = periodAsInteger - 1;

  return rates[periodIndex];
};

const getDates = (amendedWeeks) => {
  const dates = [];

  amendedWeeks.forEach((week) => {
    dates.push(week.date);
  });

  const parsedDates = [...new Set(dates)];

  parsedDates.sort((a, b) => {
    return new Date(b) - new Date(a);
  });

  return parsedDates;
};

export const genAmendedWeeksMetadata = (amendedWeeks) => {
  const dates = getDates(amendedWeeks);

  const metadata = {};

  // creates week in metadata obj

  dates.forEach((date) => {
    metadata[date] = { weeks: [] };
  });

  // adds each amended week to metadata obj.
  amendedWeeks.forEach((week) => {
    metadata[week.date].weeks.push(week);
  });

  // sorts dates by most recent.
  dates.forEach((date) => {
    metadata[date].weeks.sort((a, b) => {
      return new Date(b.dateAdded) - new Date(a.dateAdded);
    });
  });

  return metadata;
};

export function genAmendedGraphs(metadata, periods) {
  const dates = Object.keys(metadata);

  const graphs = [];

  dates.forEach((date) => {
    const graphDataset = genAmendedGraphDataset(metadata[date].weeks, periods);
    graphs.push(graphDataset);
  });

  return graphs;
}

export const checkIfPeriodsAmended = (graphs, periods) => {
  let amended = false;

  graphs.forEach((graph) => {
    const affectedPeriods = getAffectedPeriods(graph, periods);
    if (affectedPeriods.length > 0) amended = true;
  });

  return amended;
};

export const getModificationDates = (amendedWeeks) => {
  const week = amendedWeeks[0];
  const keys = Object.keys(week);
  keys.splice(0, 1);
  return keys;
};

export const genAmendedGraphDataset = (amendedWeeks, periods) => {
  const dataset = [];

  amendedWeeks.forEach((week) => {
    const datapoint = { date: week.dateAdded };
    periods.forEach((period) => {
      datapoint[period] = getRate(period, week.rates);
    });
    dataset.push(datapoint);
  });

  dataset.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });

  return dataset;
};

export const getAffectedPeriods = (amendedGraph, periods) => {
  const affected = [];

  periods.forEach((period) => {
    const value = amendedGraph[0][period];
    amendedGraph.forEach((dateAddedObj) => {
      if (dateAddedObj[period] != value) affected.push(period);
    });
  });

  return affected;
};

export const genGraphDataset = (weeks, periods) => {
  const dataset = [];

  const amendedWeeks = getAmendedWeeks(weeks);
  const amendedMetadata = genAmendedWeeksMetadata(amendedWeeks);
  const amendedDates = Object.keys(amendedMetadata);

  weeks.forEach((week, i) => {
    if (amendedDates.includes(week.date)) {
      // unless this current week obj is the most recent week, remove it.
      const mostRecentAmendedWeek =
        amendedMetadata[week.date].weeks[0].dateAdded;

      if (week.dateAdded === mostRecentAmendedWeek) {
        // add datapoint.
        const datapoint = { date: week.date, amended: true };
        periods.forEach((period) => {
          datapoint[period] = getRate(period, week.rates);
        });
        dataset.push(datapoint);
      }
      return;
    }

    const datapoint = { date: week.date, amended: false };
    periods.forEach((period) => {
      datapoint[period] = getRate(period, week.rates);
    });
    dataset.push(datapoint);
  });

  dataset.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });

  return dataset;
};

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function formatRateSetDate(dateStr) {
  const values = dateStr.split("-");
  return [values[1], values[2], values[0]].join("/");
}

export function weekContainsRateSetDate(week, rateSetDate) {
  let rsd = new Date(rateSetDate);
  let weekStart = new Date(week.date);
  let weekEnd = new Date(
    new Date(week.date).setDate(new Date(week.date).getDate() + 6)
  );

  return rsd >= weekStart && rsd <= weekEnd;
}

// Maybe more convoluted than necessary. Migrated this logic from a now deprecated function that performed a similar check.
export function amendedWeekContainsEffectiveRates(amendedWeek, weeks) {
  const matchingWeeks = [];
  const dates = [];

  weeks.forEach((week) => {
    if (amendedWeek.date === week.date) matchingWeeks.push(week);
  });

  matchingWeeks.forEach((matchingWeek) => {
    dates.push(matchingWeek.dateAdded);
  });

  dates.sort((a, b) => new Date(a) - new Date(b));
  return amendedWeek.dateAdded === dates.at(-1);
}

function isActiveWeek(week, weeks, rateSetDate) {
  return week.amended && weekContainsRateSetDate(week, rateSetDate)
    ? amendedWeekContainsEffectiveRates(week, weeks)
    : weekContainsRateSetDate(week, rateSetDate);
}

export function containsAmendedData(tableData) {
  for (let row of tableData) {
    if (row[0].amended) return true;
  }
  return false;
}

export function containsAmendedDataAtRSD(tableData) {
  for (let row of tableData) {
    console.log(row);
    if (row[0].containsRateSetDate && row[0].amended && row[0].activeWeek)
      return true;
  }
  return false;
}

export function genTableData(apr, weeks, periods, rateSetDate) {
  // const exampleRow = [
  //   {
  //     type: "week of",
  //     body: "2/17/2023",
  //     amended: false,
  //     containsRateSetDate: false,
  //     activeWeek: false
  //   },
  //   {
  //     type: "publication date",
  //     body: "2/14/2023",
  //     amended: false,
  //     containsRateSetDate: false,
  //     activeWeek: false
  //   },
  //   {
  //     type: "period",
  //     body: "2.235",
  //     amended: false,
  //     containsRateSetDate: false,
  //     activeWeek: false
  //   },
  // ];

  const tableData = [];

  const aprFloat = parseFloat(apr).toFixed(3);

  weeks.forEach((week) => {
    // create row array of objects and append to tableData.
    const row = [];

    if (
      week.amended &&
      !weekContainsRateSetDate(week, rateSetDate) &&
      !amendedWeekContainsEffectiveRates(week, weeks)
    )
      return;

    const activeWeek = isActiveWeek(week, weeks, rateSetDate);

    const weekOfCell = {
      type: "week of",
      body: week.date,
      amended: week.amended,
      containsRateSetDate: weekContainsRateSetDate(week, rateSetDate),
      activeWeek: activeWeek,
    };

    const publicationDateCell = {
      type: "publication date",
      body: week.dateAdded,
      amended: week.amended,
      containsRateSetDate: weekContainsRateSetDate(week, rateSetDate),
      activeWeek: activeWeek,
    };

    row.push(weekOfCell);
    row.push(publicationDateCell);

    periods.forEach((period) => {
      const termRate = parseFloat(getRate(period, week.rates)).toFixed(3);
      const rateSpread = (aprFloat - termRate).toFixed(3);

      const periodRateCell = {
        type: "period",
        body: termRate,
        amended: week.amended,
        containsRateSetDate: weekContainsRateSetDate(week, rateSetDate),
        activeWeek: activeWeek,
      };

      const periodSpreadCell = {
        type: "period",
        body: rateSpread,
        amended: week.amended,
        containsRateSetDate: weekContainsRateSetDate(week, rateSetDate),
        activeWeek: activeWeek,
      };

      row.push(periodRateCell);
      row.push(periodSpreadCell);
    });

    tableData.push(row);
  });

  tableData.sort((a, b) => new Date(a[0].body) - new Date(b[0].body));

  tableData.sort((a, b) => {
    if (a[0].amended && b[0].amended) {
      return new Date(a[1].body) - new Date(b[1].body);
    }
  });

  return tableData;
}

import { BACKEND_URL } from "../../../API_ENDPOINT";
import { formatDate } from "./utils";

export const GET_WEEKS = async (startDate, endDate, loanType, tabSelected) => {
  // add extra marging (if you will) to our date search, to account for dates being added at different times.
  // ie, we're searching for aug 1st at 00:00, and we have stored a week for aug 1st at 07:00. Even though we're looking
  // for dates matching aug 1, that stored week won't come back from the search criteria because time is incorporated.

  // assuming we're locking the start and end dates to be mondays, this will continue to select the correct date.
  // since the main graph does not select by mondays, we don't apply the offset for it.
  let endDateOffset = endDate;

  if (tabSelected === "calculator") {
    endDateOffset = formatDate(
      new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 2))
    );
  }

  console.log({ startDate, endDateOffset });

  const response = await fetch(`${BACKEND_URL}/apor`, {
    method: "POST",
    body: JSON.stringify({ startDate, endDate: endDateOffset, loanType }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const json = await response.json();
  const { accepted, matching } = json;

  if (accepted) return matching;
};

import React from "react";
import styled from "styled-components";

import SelectDropdown from "../controls/SelectDropdown";

export function Controls(props) {
  const { metadata, handleUpdate } = props;

  return (
    <Container>
      <SelectDropdown
        handleUpdate={handleUpdate}
        name="States"
        type="states"
        data={metadata.states}
      />
      <SelectDropdown
        handleUpdate={handleUpdate}
        name="Categories"
        type="categories"
        data={metadata.categories}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0px 50px 0px;
`;

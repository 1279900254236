import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";

import Login from "./Login";
import AcronymReview from "./AcronymReview";
import Admin from "./index";
import Chart from "../chart/index";
import Charts from "pages/chart/charts/Charts";

export default function AdminRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact={true} path={path}>
        <Admin />
      </Route>
      <Route exact path={`${path}/acronym-editor`}>
        <AcronymReview />
      </Route>
      <Route path={`${path}/master-chart-data`}>
        <Chart master />
      </Route>
      <Route path={`${path}/charts`}>
        <Charts />
      </Route>
      <Route path={`${path}/users`}>
        <p>Users</p>
      </Route>
      <Route path={`${path}/login`}>
        <Login />
      </Route>
    </Switch>
  );
}

import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import { CopyIcon } from "./CopyIcon";
import { Controls } from "./Controls";
import { DeleteButton } from "./DeleteButton";
import DeleteMessage from "./DeleteMessage";

import { EditContext } from "../EditContext";

export const Card = (props) => {
  const { id, text } = props;
  const [citationText, setCitationText] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const context = useContext(EditContext);

  const handleCopy = () => {
    navigator.clipboard.writeText(id);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 7000);
  };

  const getTextAreaHeight = (text) => {
    const lines = text.length / 200;

    if (lines < 1) return "30px";
    return `${lines * 22}px`;
  };

  useEffect(() => {
    setCitationText(text);
  }, [text]);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleChange = (evt) => {
    setCitationText(evt.target.value);
  };

  const handleDiscard = () => {
    setCitationText(text);
    toggleEditMode(false);
  };

  const handleSave = () => {
    context.updateCitation(id, citationText);
  };

  return (
    <Container>
      {!isDeleting && (
        <>
          <HeaderContainer>
            <IDContainer onClick={() => handleCopy()} isCopied={isCopied}>
              <CopyIcon isCopied={isCopied} />
              {!isCopied && <CopyLabel>Copy ID to clipboard</CopyLabel>}
              {isCopied && <CopyLabel>Citation ID copied.</CopyLabel>}
            </IDContainer>
            <DeleteButton handleClick={() => setIsDeleting(true)} />
          </HeaderContainer>
          <Text show={!editMode}>{citationText}</Text>
          <Input
            show={editMode}
            value={citationText}
            onChange={handleChange}
            height={getTextAreaHeight(citationText)}
          />
          <Controls
            editMode={editMode}
            handleDiscard={handleDiscard}
            handleSave={handleSave}
            toggleEditMode={toggleEditMode}
          />
        </>
      )}
      {isDeleting && (
        <DeleteMessage id={id} toggleDelete={() => setIsDeleting(false)} />
      )}
    </Container>
  );
};

const CopyLabel = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  margin-left: 10px;
  white-space: nowrap;
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xxs};
  margin: 0px 0px 10px 0px;
  line-height: 25px;
  display: ${({ show }) => (show ? "auto" : "none")};
`;

const IDContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Input = styled.textarea`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xxs};
  height: ${({ height }) => height};
  width: 100%;
  line-height: 23px;
  display: ${({ show }) => (show ? "block" : "none")};
  margin: 10px 0px 10px 0px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 10px 0px 10px 0px;
  box-sizing: border-box;
  padding: 0px 20px 20px 20px;
  background-color: white;
  box-shadow: 1px 1px 3px 0px gray;
  border-radius: 5px;
  max-width: 95vw;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

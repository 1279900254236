import React, { useState } from "react";
import styled from "styled-components";
import chevron from "./chevron_black.png";

export default function SPFDetails(props) {
  const { text, lookups } = props.record;
  const [expanded, setExpanded] = useState(false);

  const TextRecord = (props) => {
    const { lookup } = props;

    return (
      <LookupContainer>
        <Text>{lookup.value}</Text>
        {lookup.children?.map((child) => {
          return <TextRecord key={child.value} lookup={child} />;
        })}
      </LookupContainer>
    );
  };

  return (
    <Container>
      <Button onClick={() => setExpanded(!expanded)}>
        <Chevron expanded={expanded} src={chevron} alt={"dropdown chevron"} />
        {expanded ? "Collapse Details" : "View Details"}
      </Button>
      <Content expanded={expanded}>
        <Title>Record Text:</Title>
        <Text empty={text === ""}>{text || "No records found."}</Text>

        <Title>Allowed domains:</Title>
        {!lookups && <Text empty>No records found.</Text>}

        <Lookups>
          {lookups?.map((lookup) => {
            return <TextRecord key={lookup.value} lookup={lookup} />;
          })}
        </Lookups>
      </Content>
    </Container>
  );
}

const Text = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.sm};
  font-style: ${({ empty }) => (empty ? "italic" : "normal")};

  @media (max-width: 800px) {
    font-size: ${({ theme }) => theme.text.xs};
  }
`;

const Title = styled(Text)`
  font-weight: bold;
`;

const Button = styled.button`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xxs};
  outline: none;
  border: none;
  background-color: #e7e7e7;

  cursor: pointer;
  box-sizing: border-box;
  padding: 7px 10px 7px 5px;
  border-radius: 2px;
  margin-top: 5px;
  transition: translate box-shadow 100ms linear;

  &:hover {
    transition: translate box-shadow 100ms linear;
    transform: translateY(-1px);
    box-shadow: 1px 1px 2px 0px #636363;
  }
`;

const Chevron = styled.img`
  margin-bottom: ${({ expanded }) => (expanded ? "-1px" : "-2px")};
  margin-right: 3px;
  transform: ${(props) =>
    props.expanded ? "rotate(180deg) scale(0.75)" : "scale(0.75)"};
`;

const LookupContainer = styled.div`
  margin-left: 20px;
`;

const Lookups = styled.div`
  margin-left: -20px;
`;

const Content = styled.div`
  display: ${({ expanded }) => (expanded ? "block" : "none")};
`;

const Container = styled.div``;

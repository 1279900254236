import React from "react";
import styled from "styled-components";

export default function StatusIcon(props) {
  if (!props.pass)
    return (
      <>
        <Image src="fail_icon.png" alt="fail icon" />
      </>
    );
  return (
    <>
      <Image src="success_icon.png" alt="success icon" />
    </>
  );
}

const Image = styled.img`
  align-self: center;

  @media (max-width: 800px) {
    transform: scale(0.8);
    margin-left: -3px;
  }
`;

export const palette = [
  "#5ca793",
  "#a2b86c",
  "#ebc844",
  "#ecaa38",
  "#ef8b2c",
  "#f16c20",
  "#d94e1f",
  "#c02e1d",
  "#0d3c55",
  "#0f5b78",
  "#117899",
  "#1395ba",
];

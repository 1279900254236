import React from "react";
import styled from "styled-components";

import { Title } from "components/Title";
import { Controls } from "./Controls";
import { CREATE_NEW_CHART } from "./API";
import { Redirect } from "react-router-dom";

export default class NewChartForm extends React.Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
    this.state = {
      form: {
        title: "",
        categories: [],
        states: [],
      },
      formErrors: {
        title: false,
        categories: false,
        states: false,
      },
      submitted: false,
      accepted: false,
      chartID: "",
    };
  }

  componentDidMount() {
    this.inputRef.current.focus();
  }

  handleUpdate = (key, value) => {
    this.setState({ form: { ...this.state.form, [key]: value } });
  };

  handleSubmit = async () => {
    const { states, categories, title } = this.state.form;

    this.setState(
      {
        formErrors: {
          states: states.length === 0,
          categories: categories.length === 0,
          title: title === "",
        },
      },
      async () => {
        let formComplete = true;
        const { formErrors } = this.state;

        for (let key of Object.keys(formErrors)) {
          if (formErrors[key]) formComplete = false;
        }

        if (formComplete) {
          const token = JSON.parse(localStorage.getItem("token"));
          this.setState({ submitted: true });
          const result = await CREATE_NEW_CHART(
            states,
            categories,
            title,
            token
          );
          console.log("result", result);
          this.setState({ accepted: result.accepted, chartID: result.chartID });
        }
      }
    );
  };

  render() {
    const { toggle, metadata } = this.props;
    const { formErrors, submitted, accepted, chartID } = this.state;

    if (!accepted) {
      return (
        <Container>
          <Form>
            <CloseButton onClick={toggle}>X</CloseButton>
            <Content>
              <Title size="lg">Create a New Chart</Title>
              <Input
                ref={this.inputRef}
                onChange={(evt) => this.handleUpdate("title", evt.target.value)}
                placeholder="Enter a title..."
              />
              <ErrorMessage active={formErrors.title}>
                Please enter a title
              </ErrorMessage>
              <TextArea placeholder="Enter a description (optional)..." />
              <Underline />
              <Controls handleUpdate={this.handleUpdate} metadata={metadata} />
              <ErrorMessage active={formErrors.categories}>
                Please select at least one category.
              </ErrorMessage>
              <ErrorMessage active={formErrors.states}>
                Please select at least one state.
              </ErrorMessage>
              {submitted && <Spinner />}
              {!submitted && (
                <CreateButton onClick={this.handleSubmit}>
                  Create New Chart
                </CreateButton>
              )}
            </Content>
          </Form>
        </Container>
      );
    }
    return <Redirect push to={`/charts/${chartID}`} />;
  }
}

const Spinner = styled.div`
  width: 18px;
  height: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: ${({ theme }) => theme.colors.mainGold};
  animation-name: spin;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  @keyframes spin {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;

const ErrorMessage = styled.p`
  display: ${({ active }) => (active ? "flex" : "none")};
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  margin-top: 30px;
  padding: 10px;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  border: 2px solid #5c5c5c;
  border-radius: 2px;
  background-color: transparent;
  resize: none;
  overflow-y: scroll;
  outline: none;
  margin: 0px 0px 30px 0px;

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.mainGold};
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 15px 20px 20px 20px;
`;

const CreateButton = styled.div`
  height: 45px;
  width: 100%;
  font-weight: bold;
  border: 3px solid ${({ theme }) => theme.colors.mainGold};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.mainGold};
  cursor: pointer;
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  margin-bottom: 10px;
  color: #262626;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.mainGold};
    box-shadow: 1px 1px 1px 1px lightgray;
    transform: translateY(-1px);
  }
`;

const Underline = styled.div`
  height: 2px;
  width: 100%;
  background-color: #202020;
  margin: 10px 0px 20px 0px;
`;

const CloseButton = styled.button`
  margin-top: 25px;
  margin-left: 1px;
  outline: none;
  border: none;
  font-weight: normal;
  background-color: transparent;
  font-size: 22px;
  position: absolute;
  cursor: pointer;
`;

const Input = styled.input`
  height: 42px;
  min-width: 500px;
  width: 100%;
  margin-top: 30px;
  padding-left: 15px;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.sm};
  border: 2px solid #5c5c5c;
  border-radius: 2px;
  background-color: transparent;
  outline: none;
  margin: 35px 0px 30px 0px;

  &:focus {
    border-radius: 0px;
    border: 2px solid ${({ theme }) => theme.colors.mainGold};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  position: fixed;
  left: -15px;
`;

const Form = styled.div`
  box-sizing: border-box;
  padding: 5px 20px 10px 20px;
  box-shadow: 2px 2px 4px 1px lightgray;
  background-color: white;
  border-radius: 5px;
  z-index: 2;
`;

import React from "react";
import styled from "styled-components";
import { nanoid } from "nanoid";

import chevron from "./chevron_black.png";

export default class SelectDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      dropdown: false,
    };
  }

  toggleDropdown = (value) => {
    this.setState({ dropdown: value });
  };

  addActive = (name, array) => {
    let updatedArr = [...array, name];
    this.setState({ options: updatedArr }, () => {
      this.props.handleUpdate(this.props.type, updatedArr);
    });
  };

  removeActive = (name, array) => {
    let updatedArr = array;
    const index = updatedArr.indexOf(name);

    updatedArr.splice(index, 1);
    this.setState({ options: updatedArr }, () => {
      this.props.handleUpdate(this.props.type, updatedArr);
    });
  };

  handleCheckInput = (evt) => {
    const { checked, name } = evt.target;
    const { options } = this.state;

    if (checked) this.addActive(name, options);
    else this.removeActive(name, options);
  };

  checkIfActive = (name, array) => {
    let isActive = false;

    for (let elem of array) {
      if (name === elem) isActive = true;
    }
    return isActive;
  };

  render() {
    const { options, dropdown } = this.state;
    const { name } = this.props;

    return (
      <Container>
        {/* Main Button (Collapsed)*/}
        <Button dropdown={dropdown} onClick={() => this.toggleDropdown(true)}>
          <ButtonContent>
            <Title>Select {name}</Title>
            <Chevron src={chevron} alt={"Chevron"} />
          </ButtonContent>
        </Button>

        {/*Dropdown with Options (expanded)*/}
        <Dropdown dropdown={dropdown}>
          <DropdownHeader onClick={() => this.toggleDropdown(false)}>
            <Title>Select {name}</Title>
            <Chevron src={chevron} alt={"Chevron"} inverted />
          </DropdownHeader>

          <Span />

          <Select>
            {this.props.data.map((option) => {
              const isChecked = this.checkIfActive(option, options);
              return (
                <Option htmlFor={option} key={nanoid()}>
                  <OptionInput
                    type="checkbox"
                    name={option}
                    id={option}
                    onChange={(evt) => {
                      this.handleCheckInput(evt);
                    }}
                    checked={isChecked}
                  />
                  <OptionLabel htmlFor={option}>{option}</OptionLabel>
                </Option>
              );
            })}
          </Select>
        </Dropdown>
      </Container>
    );
  }
}

const Title = styled.p`
  margin: 10px 0px 10px 0px;
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.sm};
`;

const Select = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;
  max-height: 180px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Option = styled.div``;

const OptionInput = styled.input`
  cursor: pointer;
  margin: 10px 20px 10px 0px;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
`;

const OptionLabel = styled.label`
  cursor: pointer;
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  user-select: none;
`;

const Dropdown = styled.div`
  display: ${(props) => (props.dropdown ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  background-color: white;
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.3);
  padding: 0px 10px 15px 10px;
  border-radius: 5px;
`;

const Container = styled.div`
  width: 48%;
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0px 5px 0px;
`;

const Button = styled.div`
  display: ${(props) => (props.dropdown ? "none" : "flex")};
  background-color: white;
  box-sizing: border-box;
  padding: 0px 10px 0px 10px;
  cursor: pointer;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 35px;
  min-width: 170px;
`;

const Chevron = styled.img`
  margin-top: 2px;
  transform: ${(props) => (props.inverted ? "rotate(180deg);" : "")};
`;

const Span = styled.div`
  width: 100%;
  height: 2px;
  margin: 3px 0px 3px 0px;
  position: static;
  background-color: ${(props) => props.theme.colors.mainGold};
`;

const DropdownHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 33px;
`;

const DropdownTitleContainer = styled.div`
  display: flex;
`;

import React, { useState } from "react";
import styled from "styled-components";

import DMARCRecord from "./DMARCRecord";
import SPFRecord from "./SPFRecord";
import buttonClose from "./button_close.svg";

export default function DomainCard(props) {
  const [hovered, setHovered] = useState(false);
  const { deleteDomain } = props;
  const { url, records } = props.data;

  return (
    <Container
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Header>
        <Title>{url}</Title>
        <DeleteButton hovered={hovered} onClick={() => deleteDomain(url)}>
          <DeleteIcon src={buttonClose} alt="Domain card close button" />
        </DeleteButton>
      </Header>
      <RecordsContainer>
        <SPFRecord record={records[0]} />
        <DMARCRecord record={records[1]} />
      </RecordsContainer>
    </Container>
  );
}

const DeleteIcon = styled.img``;

const Container = styled.div`
  width: 50vw;
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 3px 5px 1px #b8b8b8;
  padding: 10px 20px 25px 20px;
  margin: 15px 0px 25px 0px;

  @media (max-width: 1400px) {
    width: 60%;
  }

  @media (max-width: 1200px) {
    width: 70%;
  }

  @media (max-width: 800px) {
    width: 93%;
    margin: 20px 0px 20px 0px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const DeleteButton = styled.button`
  display: ${({ hovered }) => (hovered ? "block" : "none")};
  margin-right: 10px;
  margin-top: -5px;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 22px;
  height: 22px;

  @media (max-width: 1200px) {
    display: block;
  }

  &:hover {
    cursor: pointer;
    transform: translate(0, -1px);
    transition: transform 50ms linear;
  }
`;

const Title = styled.h1`
  font-family: ${({ theme }) => theme.titleFont};
  font-size: ${({ theme }) => theme.text.lg};
  font-weight: normal;
  background-color: ${({ theme }) => theme.colors.offWhite};
  padding: 0px 8px 2px 8px;
  border-radius: 2px;
  box-shadow: 1px 1px 3px 0px lightgray;
  margin-bottom: 15px;
`;

const Subtitle = styled.h3``;

const Text = styled.p``;

const RecordsContainer = styled.div``;

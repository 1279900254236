export const periodRange = [
  "1 year",
  "2 year",
  "3 year",
  "4 year",
  "5 year",
  "6 year",
  "7 year",
  "8 year",
  "9 year",
  "10 year",
  "11 year",
  "12 year",
  "13 year",
  "14 year",
  "15 year",
  "16 year",
  "17 year",
  "18 year",
  "19 year",
  "20 year",
  "21 year",
  "22 year",
  "23 year",
  "24 year",
  "25 year",
  "26 year",
  "27 year",
  "28 year",
  "29 year",
  "30 year",
  "31 year",
  "32 year",
  "33 year",
  "34 year",
  "35 year",
  "36 year",
  "37 year",
  "38 year",
  "39 year",
  "40 year",
  "41 year",
  "42 year",
  "43 year",
  "44 year",
  "45 year",
  "46 year",
  "47 year",
  "48 year",
  "49 year",
  "50 year",
];

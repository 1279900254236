import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";

import { EditContext } from "../EditContext";
import ColumnControls from "../ColumnControls";
export default function Controls() {
  const editContext = useContext(EditContext);
  const [scrollPos, setScrollPos] = useState(0);

  const formattedDate = new Date(editContext.previewDate).toDateString();
  const formattedTime = new Date(editContext.previewDate).toLocaleTimeString();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollPos(window.scrollY);
    });

    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  return (
    <Container scrollY={scrollPos} versionControl={editContext.versionControl}>
      <EditContent show={!editContext.versionControl}>
        <DragModeButton
          editMode={editContext.isEditing}
          onClick={() => editContext.toggleDragMode(!editContext.dragMode)}
        >
          <Circle editMode={editContext.dragMode} />
          Toggle {editContext.dragMode ? "Off" : "On"} Drag Mode
        </DragModeButton>
        <Button
          onClick={() => editContext.toggleEditMode(!editContext.isEditing)}
        >
          <Circle editMode={editContext.isEditing} />
          Toggle {editContext.isEditing ? "Off" : "On"} Edit Mode
        </Button>
        {/* <Button onClick={() => editContext.toggleVersionControl(true)}>
          Version History
        </Button> */}
        <ColumnControls />
      </EditContent>

      <VersionControlContent show={editContext.versionControl}>
        <BackButton onClick={() => editContext.toggleVersionControl(false)}>
          {"< Back"}
        </BackButton>
        <VersionDate>{formattedDate + ", " + formattedTime}</VersionDate>
        <RestoreButton
          show={!(editContext.previewDate === editContext.date)}
          onClick={() =>
            editContext.restoreChart("admin", editContext.previewDate)
          }
        >
          Restore this version
        </RestoreButton>
      </VersionControlContent>
    </Container>
  );
}

const EditContent = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const VersionControlContent = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30px;
`;

const Circle = styled.div`
  width: 12px;
  height: 12px;
  margin: 0px 10px 0px -5px;
  border-radius: 50%;
  background-color: ${({ editMode, theme }) =>
    editMode ? theme.colors.mainGold : "gray"};
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 0px gray;
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  padding: 5px 15px 5px 15px;
  margin: 2px 15px 0px 15px;
  cursor: pointer;
`;

const RestoreButton = styled(Button)`
  display: ${({ show }) => (show ? "block" : "none")};
  background-color: ${({ theme }) => theme.colors.mainGold};
  color: white;
  margin-top: -2px;

  &:hover {
    transform: translateY(-1px);
  }
`;

const BackButton = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  margin: 0px;
  cursor: pointer;
`;

const VersionDate = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.sm};
  margin: 0px 30px 0px 30px;
`;

const DragModeButton = styled(Button)`
  opacity: ${({ editMode }) => (editMode ? "1" : "0.3")};
  pointer-events: ${({ editMode }) => (editMode ? "" : "none")};
  user-select: ${({ editMode }) => (editMode ? "" : "none")};
`;

const Container = styled.div.attrs(({ scrollY, versionControl }) => ({
  style: {
    boxShadow: `${
      scrollY > 0 ? "1px 1px 3px 0px gray" : "1px 1px 0px 0px lightgray"
    }`,
    backgroundColor: `${scrollY > 0 || versionControl ? "white" : "#f7f7f2"}`,
  },
}))`
  height: 55px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  top: 0px;
  z-index: 1;
  position: fixed;
`;

import React, { useState, useContext } from "react";
import { nanoid } from "nanoid";
import styled from "styled-components";

import { EditContext } from "../EditContext";

import { Title } from "components/Title";
import { Card } from "./Card";
import { DeleteButton } from "./DeleteButton";

export default function Citations(props) {
  const [editMode, setEditMode] = useState(false);
  const { citations } = props;
  const ids = Object.keys(citations);
  const context = useContext(EditContext);

  return (
    <Container>
      <TitleContainer>
        <Title alignTitle={"left"} size="lg" styles={"width: auto;"}>
          Citations
        </Title>
        <EditButton editMode={editMode} onClick={() => setEditMode(!editMode)}>
          {editMode ? "X Stop Editing" : "Edit Citations"}
        </EditButton>
        <AddNewButton
          editMode={editMode}
          onClick={() => context.addCitation("")}
        >
          + Create new citation
        </AddNewButton>
      </TitleContainer>
      {!editMode &&
        ids.map((id, i) => {
          return <Text key={nanoid()}>{`[${i + 1}] ${citations[id]}`}</Text>;
        })}
      {editMode &&
        ids.map((id, i) => {
          return <Card key={nanoid()} id={id} text={citations[id]} />;
        })}
    </Container>
  );
}

const Text = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xxs};
  margin: 10px 0px 10px 0px;
  line-height: 25px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  margin-top: 50px;
  margin-left: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
`;

const EditButton = styled.button`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xxs};
  background-color: white;
  color: #2e2e2e;
  letter-spacing: 0.03rem;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 2px;
  padding: 0px 10px 0px 10px;
  height: 35px;
  margin-left: 20px;
  margin-top: 2px;
  cursor: pointer;
  box-shadow: 1px 1px 2px 0px gray;

  &:hover {
    transform: translateY(-1px);
    background-color: ${({ theme }) => theme.colors.mainGold};
    color: white;
  }
`;

const AddNewButton = styled(EditButton)`
  outline: 2px solid ${({ theme }) => theme.colors.mainGold};
  visibility: ${({ editMode }) => (editMode ? "visible" : "hidden")};
  background-color: ${({ editMode }) => (editMode ? "white" : "transparent")};
  box-shadow: 2px 2px 2px 0px gray;

  &:hover {
    transform: translateY(-1px);
    background-color: ${({ theme }) => theme.colors.mainGold};
    color: white;
  }
`;

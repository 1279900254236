import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { BackButton } from "components/resources/BackButton";
import { Title } from "components/Title";
import Navbar from "components/navbar";
import { Footer } from "components/Footer";
import { Spinner } from "./Spinner";

import { GraphInput } from "./GraphInput";
import { Graph } from "./Graph";
import { GET_WEEKS } from "./API";
import {
  getAmendedWeeks,
  genAmendedWeeksMetadata,
  genAmendedGraphs,
  checkIfPeriodsAmended,
} from "./utils";
import { AmendedGraphs } from "./AmendedGraphs";
import { AmendedMessage } from "./AmendedMessage";

import Calculator from "./calculator";

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function genDefaultStartDate() {
  const currentDate = new Date();
  let offset = 12;

  if (window.innerWidth < 900) offset = 6;
  let startDate = currentDate.setMonth(currentDate.getMonth() - offset);
  startDate = new Date(startDate).toLocaleDateString("en-US");
  return formatDate(startDate);
}

function genDefaultEndDate() {
  const currentDate = new Date();

  let endDate = currentDate.setDate(currentDate.getDate() + 7);
  return formatDate(new Date(endDate).toLocaleDateString());
}

function genCalcDefaultStartDate() {
  const monday = new Date(
    new Date().setDate(new Date().getDate() - (6 + new Date().getDay()))
  );
  return formatDate(monday);
}

function genCalcDefaultEndDate() {
  const monday = new Date(
    new Date().setDate(new Date().getDate() + (8 - new Date().getDay()))
  );
  return formatDate(monday);
}

function containsAmendedPeriods(metadata, periods) {
  const graphs = genAmendedGraphs(metadata, periods);
  const periodsAmended = checkIfPeriodsAmended(graphs, periods);
  return periodsAmended;
}

export default function APOR() {
  const [loanType, setLoanType] = useState("fixed");
  const [periods, setPeriods] = useState(["15 year", "30 year"]);
  const [startDate, setStartDate] = useState(genDefaultStartDate());
  const [endDate, setEndDate] = useState(genDefaultEndDate());
  const [displayDates, setDisplayDates] = useState([startDate, endDate]);
  const [graphSynced, setGraphSynced] = useState(true);
  const [calcSynced, setCalcSynced] = useState(true);
  const [calcStartDate, setCalcStartDate] = useState(genCalcDefaultStartDate());
  const [calcEndDate, setCalcEndDate] = useState(genCalcDefaultEndDate());
  const [weeks, setWeeks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabSelected, setTabSelected] = useState("graph");
  const [amendedMetadata, setAmendedMetadata] = useState({});
  const [error, setError] = useState("");

  function handlePeriodUpdate(periods) {
    setPeriods(periods);
    setCalcSynced(false);
    setGraphSynced(false);

    if (periods.length < 1) {
      setError("Please select at least one term.");
      return;
    }
    setError("");
    return;
  }

  function handleDateUpdate(startDate, endDate) {
    setStartDate(startDate);
    setEndDate(endDate);
    setGraphSynced(false);

    if (
      new Date(startDate) > new Date(endDate) ||
      new Date(endDate) < new Date(startDate)
    ) {
      setError("Please select a valid date range.");
      return;
    }

    setError("");
    return;
  }

  function handleCalcDateUpdate(calcStartDate, calcEndDate) {
    setCalcStartDate(calcStartDate);
    setCalcEndDate(calcEndDate);
    setCalcSynced(false);

    if (
      new Date(calcStartDate) > new Date(calcEndDate) ||
      new Date(calcEndDate) < new Date(calcStartDate)
    ) {
      setError("Please select a valid date range.");
      return;
    }

    setError("");
    return;
  }

  async function loadWeeks() {
    setLoading(true);
    setWeeks([]);
    const weeks = await GET_WEEKS(startDate, endDate, loanType, "graph");

    const amendedWeeks = getAmendedWeeks(weeks);
    const amendedMetadata = genAmendedWeeksMetadata(amendedWeeks);

    setAmendedMetadata(amendedMetadata);
    setWeeks(weeks);
    setDisplayDates([startDate, endDate]);
    setLoading(false);
    setGraphSynced(true);
  }

  useEffect(() => {
    loadWeeks();
    return () => {};
  }, [loanType]);

  const showAmended = containsAmendedPeriods(amendedMetadata, periods);

  return (
    <Container>
      <BackButtonContainer>
        <BackButton location="/resources" text="< Resources" />
      </BackButtonContainer>
      <Title size="xl" styles={TitleStylesOverride} spanWidth="200px">
        APOR Rate Tool
      </Title>

      <Text>
        APOR rate data is published digitally each week by the CFPB. Some weeks
        are later amended, but the original rate data is still considered valid
        at the time of publication. We independently monitor and store the data
        published to log amendments in real time.
      </Text>

      <GraphInput
        setLoanType={setLoanType}
        handlePeriodUpdate={handlePeriodUpdate}
        periods={periods}
        loanType={loanType}
        startDate={startDate}
        endDate={endDate}
        handleCalcDateUpdate={handleCalcDateUpdate}
        handleDateUpdate={handleDateUpdate}
        calcStartDate={calcStartDate}
        calcEndDate={calcEndDate}
        tabSelected={tabSelected}
        setTabSelected={setTabSelected}
        loadWeeks={loadWeeks}
        error={error}
        graphSynced={graphSynced}
        loading={loading}
      />

      <RateGraphTab show={tabSelected === "graph"}>
        <AmendedMessageContainer>
          <AmendedMessage
            show={showAmended}
            tabSelected={tabSelected}
            handleScroll={() => {
              const scrollTarget = document.getElementById("amendedgraphs");
              scrollTarget.scrollIntoView();
            }}
          />
        </AmendedMessageContainer>

        <GraphContainer show={!loading} synced={graphSynced}>
          <Header>
            <TitleContainer>
              <GraphTitle>{`${
                loanType.charAt(0).toUpperCase() + loanType.slice(1)
              } Offer Rate Data ${displayDates[0]} to ${
                displayDates[1]
              }`}</GraphTitle>
              <Underline></Underline>
            </TitleContainer>
          </Header>

          <Graph
            loading={loading}
            periods={periods}
            periodsAmended={showAmended}
            weeks={weeks}
            amendedMetadata={amendedMetadata}
          />
        </GraphContainer>

        <LoadingContainer show={loading}>
          <Spinner />
          <LoadingText>Loading...</LoadingText>
        </LoadingContainer>

        <AmendedGraphs
          loading={loading}
          metadata={amendedMetadata}
          periods={periods}
        />
      </RateGraphTab>

      <CalculatorTab show={tabSelected === "calculator"}>
        <Calculator
          calcSynced={calcSynced}
          setCalcSynced={setCalcSynced}
          periods={periods}
          loanType={loanType}
          setCalcStartDate={setCalcStartDate}
          setCalcEndDate={setCalcEndDate}
          calcEndDate={calcEndDate}
          calcStartDate={calcStartDate}
        />
      </CalculatorTab>

      <Footer slim />
      <Navbar alwaysDisplay />
    </Container>
  );
}

const Text = styled.p`
  font-size: ${({ theme }) => theme.text.xs};
  font-family: ${({ theme }) => theme.textFont};
  text-align: center;
  line-height: 28px;
  margin-bottom: 0px;
  max-width: 70vw;

  @media (max-width: 800px) {
    font-size: ${({ theme }) => theme.text.xxs};
    max-width: 90vw;
    line-height: 1.8;
  }
`;

const RateGraphTab = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 70px;
`;

const CalculatorTab = styled(RateGraphTab)`
  display: ${({ show }) => (show ? "flex" : "none")};
`;

const TitleContainer = styled.div`
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    justify-content: center;
    align-items: center;
  }
`;

const Underline = styled.span`
  margin-top: 8px;
  margin-bottom: -6px;
  background: #e1a915;
  width: 90%;
  height: 3px;

  @media (max-width: 900px) {
    width: 70%;
  }

  @media (max-width: 600px) {
    width: 50%;
  }
`;

const GraphContainer = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  ${({ synced }) => (synced ? "" : "filter: opacity(0.9) blur(2px); ")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #202020;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px lightgray;
  margin-top: 10px;
  width: 100%;

  @media (max-width: 1400px) {
    margin-top: 0px;
  }

  @media (max-width: 900px) {
    width: 98%;
  }
`;

const LoadingText = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.sm};
  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0px 0px 50px;

  @media (max-width: 900px) {
    width: 100%;
    justify-content: center;
    margin: 15px 0px 0px 10px;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
`;

const AmendedMessageContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`;

const BackButtonContainer = styled.div`
  width: 100%;
  z-index: 10;

  @media (max-width: 900px) {
    display: none;
  }
`;

const GraphTitle = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: 26px;
  margin: 0px 0px 0px 0px;
  color: white;

  @media (max-width: 1400px) {
    padding: 0px 10px 0px 10px;
    font-size: 20px;
  }

  @media (max-width: 1000px) {
    font-size: 18px;
    text-align: center;
  }

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const TitleStylesOverride = `
  margin-bottom: 0px;
  margin-top: -30px;

  @media (max-width: 900px) {
    margin-top: 15px;
    margin-bottom: 2vh; 
    text-align: center;
  };
`;

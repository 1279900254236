import React from "react";
import styled from "styled-components";

import Chart from "./Chart";
import Controls from "./controls/index";

import { withRouter } from "react-router-dom";

import {
  genTitleRowArray,
  genCanonicalData,
  updateObjectByID,
  updateChildrenByParentID,
  populateTreeStructure,
  findLowestLevel,
  findCategories,
  addAColumn,
  removeChildByIDs,
  removeLastColumn,
  addCellToParent,
  addCitation,
  deleteCitation,
  updateCitation,
  createPreviewChartData,
} from "./utils";

import {
  FETCH_DATA,
  FETCH_CITATIONS,
  FETCH_VERSIONS,
  POST_DATA,
  RESTORE_CHART_VERSION,
} from "./API";

import { nanoid } from "nanoid";
import { EditContext } from "./EditContext";
import VersionFlyout from "./controls/VersionFlyout";

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      master: false,
      editMode: true,
      data: null,
      dataCopy: null,
      canonicalData: [],
      titleData: [],
      citations: [],
      dragMode: false,
      versionControl: false,
      versions: null,
      date: "",
      meta: null,
      metaCopy: null,
      previewDate: "",
      title: "",
      id: "",
    };
  }

  async componentDidMount() {
    let chartObject;

    if (this.props.master || this.state.master) {
      chartObject = await FETCH_DATA("admin", true);
    } else {
      chartObject = await FETCH_DATA(this.props.match.params.id, false);
    }

    const data = chartObject.chart;
    const date = chartObject.date;
    const meta = { preview: chartObject.meta.preview };
    const versions = await FETCH_VERSIONS();
    const id = chartObject.id;
    const citations = await FETCH_CITATIONS(data);
    const canonicalData = genCanonicalData(data, this.state.editMode);
    const titleData = genTitleRowArray(canonicalData[0]);

    this.setState({
      data: data,
      canonicalData: canonicalData,
      titleData: titleData,
      citations: citations,
      id: id,
      title: chartObject.title,
      versions: versions,
      date: date,
      meta: meta,
      previewDate: date,
      master: this.props.master,
    });
  }

  postUpdate = async (data, preview) => {
    const { id, master } = this.state;
    const postStatus = await POST_DATA(data, id, master, preview);

    if (postStatus === "Accepted") {
      const chartObj = await FETCH_DATA("admin", true);
      const { date } = chartObj;
      const meta = { preview: chartObj.meta.preview };
      const versions = await FETCH_VERSIONS();

      this.setState({
        date,
        meta,
        previewDate: date,
        versions: versions,
      });
    }
  };

  deleteData = (id, parentID) => {
    const newData = removeChildByIDs(parentID, id, this.state.data);
    const preview = { type: "delete", params: { parentID, id } };
    const newCanonicalData = genCanonicalData(newData, this.state.editMode);

    this.setState(
      { data: newData, canonicalData: newCanonicalData },
      async () => {
        await this.postUpdate(newData, preview);
      }
    );
  };

  updateData = (id, key, value) => {
    const newData = updateObjectByID(id, key, value, this.state.data);
    const preview = {
      type: "update",
      params: { id, key, value },
    };
    const newCanonicalData = genCanonicalData(newData, this.state.editMode);

    this.setState(
      { data: newData, canonicalData: newCanonicalData },
      async () => {
        await this.postUpdate(newData, preview);
      }
    );
  };

  addACell = (parentID) => {
    const newData = addCellToParent(parentID, this.state.data);
    const preview = {
      type: "add",
      params: { parentID },
    };
    const newCanonicalData = genCanonicalData(newData, this.state.editMode);

    this.setState(
      { data: newData, canonicalData: newCanonicalData },
      async () => {
        await this.postUpdate(newData, preview);
      }
    );
  };

  toggleEditMode = (value) => {
    const canonicalData = genCanonicalData(this.state.data, value);
    this.setState({ editMode: value, canonicalData: canonicalData });
  };

  toggleDragMode = (value) => {
    this.setState({ dragMode: value });
  };

  toggleVersionControl = async (value) => {
    if (!value) {
      const data = this.state.dataCopy;
      const meta = this.state.metaCopy;
      const canonicalData = genCanonicalData(data, true);
      const titleData = genTitleRowArray(canonicalData[0]);

      this.setState({
        data: data,
        meta: meta,
        canonicalData: canonicalData,
        titleData: titleData,
        versionControl: false,
        dataCopy: undefined,
        metaCopy: undefined,
        editMode: true,
      });
    } else {
      this.setState(
        {
          versionControl: true,
          editMode: false,
          dataCopy: JSON.parse(JSON.stringify(this.state.data)),
          metaCopy: JSON.parse(JSON.stringify(this.state.meta)),
        },
        () => {
          this.setChartVersion(this.state.date);
        }
      );
    }
  };

  setChartVersion = async (date) => {
    const { versions } = this.state;
    const originalVersionDate = versions[versions.length - 1]?.date;

    if (date === this.state.date) {
      const chartData = JSON.parse(JSON.stringify(versions[0]?.chart));
      const preview = this.state.metaCopy.preview;

      const previewChartData = createPreviewChartData(chartData, preview);
      const newCanonicalData = genCanonicalData(previewChartData, true);
      const newTitleData = genTitleRowArray(newCanonicalData[0]);

      this.setState({
        data: previewChartData,
        titleData: newTitleData,
        meta: this.state.metaCopy,
        canonicalData: newCanonicalData,
        previewDate: date,
        editMode: false,
      });
    } else if (date === originalVersionDate) {
      const chartData = versions[versions.length - 1]?.chart;
      const newCanonicalData = genCanonicalData(chartData, true);
      const newTitleData = genTitleRowArray(newCanonicalData[0]);

      this.setState({
        data: chartData,
        titleData: newTitleData,
        canonicalData: newCanonicalData,
        previewDate: date,
        editMode: false,
      });
    } else {
      versions.forEach((version, i) => {
        if (version.date === date) {
          const preview = version.meta.preview;
          const chartData = versions[i + 1]?.chart;
          const meta = version.meta;

          const previewChartData = createPreviewChartData(chartData, preview);
          const newCanonicalData = genCanonicalData(previewChartData, true);
          const newTitleData = genTitleRowArray(newCanonicalData[0]);

          this.setState({
            data: JSON.parse(JSON.stringify(previewChartData)),
            titleData: newTitleData,
            canonicalData: newCanonicalData,
            previewDate: date,
            editMode: false,
            meta,
          });
        }
      });
    }
  };

  restoreChart = async (id, date) => {
    const completed = await RESTORE_CHART_VERSION(id, date);

    if (completed) {
      const versions = await FETCH_VERSIONS();
      this.setState({
        versionControl: false,
        versions: versions,
        dataCopy: undefined,
        editMode: true,
      });
    }
  };

  updateChildren = (parentID, newChildren) => {
    const newData = updateChildrenByParentID(
      this.state.data,
      parentID,
      newChildren
    );
    const newCanonicalData = genCanonicalData(newData, this.state.editMode);
    this.setState(
      { data: newData, canonicalData: newCanonicalData },
      async () => {
        const postStatus = await POST_DATA(
          newData,
          this.state.id,
          this.state.master
        );
        console.log("postStatus", postStatus);
      }
    );
  };

  addAColumn = (columnName) => {
    const newData = addAColumn(columnName, this.state.data);
    const newCanonicalData = genCanonicalData(newData, this.state.editMode);
    const newTitleData = genTitleRowArray(newCanonicalData[0]);

    this.setState(
      {
        data: newData,
        canonicalData: newCanonicalData,
        titleData: newTitleData,
      },
      async () => {
        const postStatus = await POST_DATA(
          newData,
          this.state.id,
          this.state.master
        );
        console.log("postStatus", postStatus);
      }
    );
  };

  deleteLastColumn = () => {
    const newData = removeLastColumn(this.state.data);
    const newCanonicalData = genCanonicalData(newData, this.state.editMode);
    const newTitleData = genTitleRowArray(newCanonicalData[0]);

    this.setState(
      {
        data: newData,
        canonicalData: newCanonicalData,
        titleData: newTitleData,
      },
      async () => {
        const postStatus = await POST_DATA(
          newData,
          this.state.id,
          this.state.master
        );
        console.log("postStatus", postStatus);
      }
    );
  };

  addAState = (stateName) => {
    const newData = this.state.data;
    const states = Object.keys(newData);
    const lowestLevel = findLowestLevel(this.state.data[states[0]]);
    const categories = findCategories(this.state.data);
    const newID = nanoid();

    const newState = {
      level: 0,
      type: "State",
      body: stateName,
      id: newID,
      children: [populateTreeStructure(1, lowestLevel, categories, newID)],
    };

    newData[stateName] = [newState];

    const newCanonicalData = genCanonicalData(newData, this.state.editMode);
    this.setState(
      { data: newData, canonicalData: newCanonicalData },
      async () => {
        const postStatus = await POST_DATA(
          newData,
          this.state.id,
          this.state.master
        );
        console.log("postStatus", postStatus);
      }
    );
  };

  addCitation = (value) => {
    const newCitationObj = addCitation(this.state.citations, value);
    this.setState({ citations: newCitationObj }, () => {
      console.log("this.state.citations", this.state.citations);
    });
  };

  updateCitation = (id, value) => {
    const newCitationObj = updateCitation(this.state.citations, id, value);
    this.setState({ citations: newCitationObj }, () => {
      console.log("this.state.citations", this.state.citations);
    });
  };

  deleteCitation = (id) => {
    const newCitationObj = deleteCitation(this.state.citations, id);
    this.setState({ citations: newCitationObj }, () => {
      console.log("this.state.citations", this.state.citations);
    });
  };

  render() {
    if (this.state.data) {
      return (
        <EditContext.Provider
          value={{
            isEditing: this.state.editMode,
            toggleEditMode: this.toggleEditMode,
            updateObjectByID: this.updateData,
            deleteObjByID: this.deleteData,
            citations: this.state.citations,
            dragMode: this.state.dragMode,
            toggleDragMode: this.toggleDragMode,
            addAState: this.addAState,
            addAColumn: this.addAColumn,
            deleteLastColumn: this.deleteLastColumn,
            addACell: this.addACell,
            addCitation: this.addCitation,
            deleteCitation: this.deleteCitation,
            updateCitation: this.updateCitation,
            versionControl: this.state.versionControl,
            setChartVersion: this.setChartVersion,
            toggleVersionControl: this.toggleVersionControl,
            date: this.state.date,
            previewDate: this.state.previewDate,
            chartData: this.state.data,
            canonicalData: this.state.canonicalData,
            restoreChart: this.restoreChart,
            meta: this.state.meta,
          }}
        >
          <Container>
            <Controls />
            <Chart
              data={this.state.canonicalData}
              citations={this.state.citations}
              titleData={this.state.titleData}
              updateChildren={this.updateChildren}
            />
            <VersionFlyout
              show={this.state.versionControl}
              versions={this.state.versions}
            />
          </Container>
        </EditContext.Provider>
      );
    } else {
      return <p>Loading....</p>;
    }
  }
}

export default withRouter(Index);

const Container = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
`;

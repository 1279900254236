import React from "react";
import styled from "styled-components";

const AmendedDiamond = () => {
  return (
    <svg
      width="15"
      height="24"
      viewBox="0 0 15 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 10.5L6.5 18L2 10.5L6.5 3L11 10.5Z"
        fill="black"
        stroke="white"
        strokeWidth="3"
      />
    </svg>
  );
};

export function AmendedMessage(props) {
  const { tabSelected } = props;

  function genText() {
    if (tabSelected === "graph")
      return "This graph contains amended rate data.";
    if (tabSelected === "calculator")
      return "This table contains amended rate data.";
  }

  return (
    <Container show={props.show}>
      <TextContainer>
        <Background show={tabSelected === "graph"}>
          <AmendedDiamond />
        </Background>
        <Text>{genText()}</Text>
      </TextContainer>
      <LinkText show={tabSelected === "graph"} onClick={props.handleScroll}>
        View Details
      </LinkText>
    </Container>
  );
}

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Background = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: #202020;
  border-radius: 2px;
  padding: 2px 0px 1px 2px;
`;

const Container = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 1px 1px 2px gray;
  padding: 0px 20px 0px 20px;
  background-color: white;
  margin: 10px 0px 10px 0px;

  @media (max-width: 1400px) {
    margin: 0px 0px 25px 0px;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xxs};
  margin-left: 8px;
`;

const LinkText = styled.button`
  display: ${({ show }) => (show ? "block" : "none")};
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xxs};
  outline: none;
  border: none;
  background-color: transparent;
  margin: 15px 0px 15px 12px;
  color: #222ce3;
  cursor: pointer;

  &:hover {
    color: #000bde;
  }

  @media (max-width: 900px) {
    margin-top: 0px;
  }
`;

import React from "react";
import styled from "styled-components";
import { Link, useRouteMatch } from "react-router-dom";

export default function AdminPanel(props) {
  const { path } = useRouteMatch();

  return (
    <Container>
      <Option to={`${path}/acronym-editor`}>Acronym Editor</Option>
      <Option to={`${path}/master-chart-data`}>Master Chart Data</Option>
      <Option to={`${path}/charts`}>Charts</Option>
      <Option to={`${path}/users`}>Manage User Permissions</Option>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
`;

const Option = styled(Link)`
  margin: 10px;
`;

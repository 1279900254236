import React from "react";
import styled from "styled-components";

import { EditContext } from "../EditContext";
import CellDeleteButton from "./CellDeleteButton";
import { CellEditButton } from "./CellEditButton";
import DiffText from "./DiffText";
import AddACellButton from "./AddACellButton";
import Controls from "./Controls";
import DeleteControls from "./DeleteControls";

export default class Cell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cellText: "",
      isCellHovered: false,
      deleteMode: false,
      cellEditMode: false,
    };

    this.cellRef = React.createRef();
  }

  static contextType = EditContext;

  checkCitation = (citations, text) => {
    const ids = Object.keys(citations);
    let newStr = text;

    for (let id of ids) {
      if (newStr.search(id) !== -1) {
        newStr = newStr.replace(id, ids.indexOf(id) + 1);
      }
    }
    return newStr;
  };

  getTextAreaHeight = (text) => {
    const lines = text.length / 30;

    if (lines < 1) return "30px";
    return `${lines * 22}px`;
  };

  handleDiscard = () => {
    this.setState({
      cellText: this.props.body,
      cellEditMode: false,
    });
  };

  handleSave = async () => {
    await this.context.updateObjectByID(
      this.props.id,
      "body",
      this.state.cellText
    );
    this.setState({ cellEditMode: false });
  };

  handleTextUpdate = (evt) => {
    const { value } = evt.target;

    if (value.length === 0) {
      this.setState({ cellText: "" });
    } else {
      this.setState({ cellText: evt.target.value });
    }
  };

  componentDidMount() {
    this.setState({ cellText: this.props.body });

    if (this.context.meta.preview) {
      const { params } = this.context.meta.preview;
      const { preview } = this.props;

      if (this.props.id === params?.id && preview) {
        this.cellRef.current.scrollIntoView({
          inline: "center",
          block: "start",
        });
        window.scrollBy(0, -100);
      }
    }
  }

  render() {
    const { cellText, cellEditMode, isCellHovered, deleteMode } = this.state;
    const { id, parent, preview, previewType, diff } = this.props;

    if (cellText !== undefined) {
      return (
        <Container
          ref={this.cellRef}
          coords={this.props.coords}
          onMouseOver={() => this.setState({ isCellHovered: true })}
          previewType={this.props?.previewType}
          onMouseLeave={() => this.setState({ isCellHovered: false })}
        >
          <CellDeleteButton
            handleClick={() => this.setState({ deleteMode: true })}
            show={
              isCellHovered &&
              this.context.isEditing &&
              !cellEditMode &&
              !deleteMode
            }
          />
          {!deleteMode && (
            <CellBodyContainer>
              <Text {...this.props} show={!cellEditMode && !preview}>
                {this.checkCitation(this.props.citations, cellText)}
              </Text>
              <DiffText
                show={preview && previewType === "update"}
                diffArr={diff}
              />
              <Input
                show={this.context.isEditing && cellEditMode}
                onChange={this.handleTextUpdate}
                value={cellText}
                height={this.getTextAreaHeight(cellText)}
              />
              <CellEditButton
                show={isCellHovered && this.context.isEditing && !cellEditMode}
                toggleEdit={() =>
                  this.setState({ cellEditMode: !cellEditMode })
                }
              />
              <AddACellButton
                show={isCellHovered && this.context.isEditing && !cellEditMode}
                toggleAddCell={() => this.context.addACell(parent)}
              />
              <Controls
                show={cellEditMode}
                handleSave={this.handleSave}
                handleDiscard={this.handleDiscard}
              />
            </CellBodyContainer>
          )}
          {deleteMode && (
            <CellBodyContainer>
              <p>Delete this cell and it's children?</p>
              <DeleteControls
                toggleDelete={(val) => this.setState({ deleteMode: val })}
                id={id}
                parent={parent}
              />
            </CellBodyContainer>
          )}
        </Container>
      );
    }
    return null;
  }
}

const CellBodyContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
`;

const Input = styled.textarea`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ name }) => (name ? "16px" : "14px")};
  height: ${({ height }) => height};
  text-align: center;
  line-height: 23px;
  display: ${({ show }) => (show ? "block" : "none")};
  width: 100%;
`;

const Text = styled.p`
  text-align: center;
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ level }) => (level <= 1 ? "18px" : "16px")};
  line-height: 23px;
  display: ${({ show }) => (show ? "block" : "none")};
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  outline: ${({ isTitle }) => (isTitle ? `` : ` 1px solid black`)};
  background-color: ${({ previewType }) => {
    if (previewType === "add")
      return "#e1a915; outline: 2px solid black; margin-left: 1px;";
    if (previewType === "delete") return "gray; outline: 2px solid black;";
    if (previewType === "update") {
      return `white; margin-left: 0px; border-left: 1px solid black; border-top: 1px solid black; box-shadow: 2px 2px 5px 2px gray;`;
    }
  }};
  grid-row: ${({ coords }) => `${coords[0]}/${coords[1]}`};
  align-items: center;
  min-width: 100px;
  min-height: 75px;
  max-width: 400px;
  padding: 20px;
`;

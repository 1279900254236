import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";

import { CHECK_ADMIN } from "./API";
import AdminPanel from "./AdminPanel";

export default function Admin() {
  const [authenticated, setAuthenticated] = useState(false);
  const [requestComplete, setRequestComplete] = useState(false);

  useEffect(() => {
    async function checkAuth() {
      const authenticated = await CHECK_ADMIN();
      setAuthenticated(authenticated);
      setRequestComplete(true);
    }

    checkAuth();
  }, []);

  if (authenticated && requestComplete) {
    return (
      <Container>
        <AdminPanel />
      </Container>
    );
  }
  if (!authenticated && requestComplete) {
    return <Redirect push to="/admin/login" />;
  }
  return <p>Loading...</p>;
}

const Container = styled.div``;

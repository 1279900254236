import React from "react";
import styled from "styled-components";

// import cellDeleteButton from "./cell_delete_button.svg";
import DeleteIcon from "./DeleteIcon";

export default function CellDeleteButton(props) {
  const { handleClick, show } = props;

  return (
    <Container show={show}>
      <DeleteIcon handleClick={handleClick} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  flex-direction: row;
  width: 100%;
  height: 50px;
  justify-content: flex-end;
`;

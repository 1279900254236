import React, { useState, useContext } from "react";
import styled from "styled-components";

import { EditContext } from "./EditContext";

export default function StateControls() {
  const [inputMode, setInputMode] = useState(false);
  const [text, setText] = useState("");
  const context = useContext(EditContext);

  const handleDiscard = () => {
    setInputMode(false);
    setText("");
  };

  const handleSave = () => {
    // call props.update
    context.addAState(text);
    setInputMode(false);
    setText("");
  };

  const handleChange = (evt) => {
    setText(evt.target.value);
  };

  if (!inputMode) {
    return (
      <Button onClick={() => setInputMode(!inputMode)}>
        + Add a state row
      </Button>
    );
  }

  if (inputMode) {
    return (
      <Container>
        <Input onChange={handleChange} value={text} />
        <DiscardButton onClick={handleDiscard}>Discard</DiscardButton>
        <SaveButton onClick={handleSave}>Save</SaveButton>
      </Container>
    );
  }
}

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: 2px solid ${({ theme }) => theme.colors.mainGold};
  background-color: white;
  border-radius: 3px;
  box-shadow: 1px 1px 2px 0px gray;
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  padding: 7px 15px 7px 15px;
  margin: 15px 15px 0px 0px;
  cursor: pointer;
  max-width: 200px;
`;

const Input = styled.input``;

const SaveButton = styled(Button)``;

const DiscardButton = styled(Button)``;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

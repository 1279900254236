import React from "react";
import styled from "styled-components";

export default function Chevron(props) {
  const { expanded, hovered, leaf } = props;

  return (
    <Container expanded={expanded} hovered={hovered} leaf={leaf}>
      <svg
        width="9"
        height="14"
        viewBox="0 0 9 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L7 7L1 13"
          stroke={expanded || hovered ? "black" : "gray"}
          strokeWidth="2"
        />
      </svg>
    </Container>
  );
}

const Container = styled.div`
  transform: ${({ expanded }) => (expanded ? "rotate(90deg)" : "")};
  display: ${({ leaf }) => (leaf ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  margin-right: 7px;
  margin-top: 1px;
`;

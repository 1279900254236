import React, { useState } from "react";
import styled from "styled-components";

import { Redirect } from "react-router-dom";

export default function PageNotFound() {
  const [redirect, setRedirect] = useState(false);

  if (!redirect) {
    return (
      <Page>
        <Card>
          <Logo
            src="MortgageBankingLogo_small.png"
            alt="MortgageBanking Logo"
          />
          <Title>404: Page Not Found</Title>
          <Message>Looks like this page doesn't exist.</Message>
          <BackButton onClick={() => setRedirect(true)}>
            {"< Homepage"}
          </BackButton>
        </Card>
      </Page>
    );
  }
  return <Redirect push to="/" exact />;
}

const Page = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img``;

const Title = styled.h1`
  font-size: ${({ theme }) => theme.text.lg};
  font-family: ${({ theme }) => theme.textFont};
  margin-bottom: 10px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: white;
  padding: 50px;
  box-shadow: 1px 1px 3px 1px lightgray;
`;

const Message = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.sm};
`;

const BackButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: ${({ theme }) => theme.text.sm};
  margin-top: 10px;

  &:hover {
    text-decoration: underline;
  }
`;

import React from "react";
import styled from "styled-components";

export function AmendedLegend(props) {
  const { show } = props;

  return (
    <Container show={show}>
      <Legend>
        <Item>
          <Key color={"#F5DA8F"} />
          <Description>Valid rates at time of publication.</Description>
        </Item>
        <Item>
          <Key color={"#E1A915"} />
          <Description>
            Currently valid rates for selected Rate Set Date.
          </Description>
        </Item>
      </Legend>
    </Container>
  );
}

const Legend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Container = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 1px 1px 1px 1px lightgray;
  border-radius: 5px;
  padding: 10px;
`;

const Key = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${({ color }) => color};
  box-shadow: 1px 1px 1px gray;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 5px 0px 5px;

  @media (max-width: 900px) {
    margin: 5px 0px 5px 0px;
  }
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.text.xxs};
  margin: 0px 0px 1px 7px;
  font-family: ${({ theme }) => theme.textFont};
`;

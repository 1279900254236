import React, { useState } from "react";
import styled from "styled-components";
import { Dropdown } from "./Dropdown";
import { TabSelect } from "./TabSelect";
import { Button } from "./Button.style.js";

import { periodRange } from "./periodRange";

export function GraphInput(props) {
  const {
    setLoanType,
    periods,
    handlePeriodUpdate,
    tabSelected,
    setTabSelected,
    loanType,
    handleCalcDateUpdate,
    handleDateUpdate,
    loadWeeks,
    error,
    loading,
    graphSynced,
  } = props;

  const [adjustablePeriods, setAdjustablePeriods] = useState([
    "7 year",
    "30 year",
  ]);

  const [fixedPeriods, setFixedPeriods] = useState(["15 year", "30 year"]);
  const { startDate, endDate, calcStartDate, calcEndDate } = props;

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function handleLoanTypeUpdate(loanType) {
    if (loanType === "fixed") {
      setAdjustablePeriods(periods);
      setLoanType(loanType);
      handlePeriodUpdate(fixedPeriods);
    }

    if (loanType === "adjustable") {
      setFixedPeriods(periods);
      setLoanType(loanType);
      handlePeriodUpdate(adjustablePeriods);
    }
  }

  function formatPeriodsTitle(periods) {
    let str = "";

    periods.forEach((period, i) => {
      if (i >= 4) return;

      if (i == 3) {
        str = str + "...";
        return;
      }

      if (i === periods.length - 1) {
        str = str + period;
        return;
      }
      str = str + period + ", ";
    });

    return str;
  }

  function routeStartDateUpdate(evt) {
    const { value } = evt.target;

    if (value === "") return;
    if (value.length > 10) return;

    if (tabSelected === "calculator") {
      handleCalcDateUpdate(value, calcEndDate);
    }
    if (tabSelected === "graph") {
      handleDateUpdate(value, endDate);
    }
  }

  function routeEndDateUpdate(evt) {
    const { value } = evt.target;

    if (value === "") return;
    if (value.length > 10) return;

    if (tabSelected === "calculator") {
      handleCalcDateUpdate(calcStartDate, value);
    }
    if (tabSelected === "graph") {
      handleDateUpdate(startDate, value);
    }
  }

  function routeStartDate() {
    if (tabSelected === "calculator") {
      return calcStartDate;
    }
    if (tabSelected === "graph") {
      return startDate;
    }
  }

  function routeEndDate() {
    if (tabSelected === "calculator") {
      return calcEndDate;
    }
    if (tabSelected === "graph") {
      return endDate;
    }
  }

  return (
    <Container>
      <TabSelect tabSelected={tabSelected} setTabSelected={setTabSelected} />
      <InputRow>
        <Text>Select Loan Type:</Text>
        <LoanSelect onChange={(evt) => handleLoanTypeUpdate(evt.target.value)}>
          <option value="fixed">Fixed</option>
          <option value="adjustable">Adjustable</option>
        </LoanSelect>
        <Text>{loanType === "fixed" ? "Loan Terms:" : "1st Adjustment:"}</Text>
        <Dropdown
          options={periodRange}
          activeOptions={periods}
          title={formatPeriodsTitle(periods)}
          handleUpdate={(activePeriods) => {
            handlePeriodUpdate(activePeriods);
          }}
        />
        <Text>Start Date:</Text>
        <DateInput
          name="startDate"
          type="date"
          value={routeStartDate()}
          max={routeEndDate()}
          min={"2017-01-02"}
          onChange={(evt) => routeStartDateUpdate(evt)}
        />
        <Text>End Date:</Text>
        <DateInput
          name="endDate"
          type="date"
          value={routeEndDate()}
          max={formatDate(new Date().setDate(new Date().getDate() + 7))}
          min={routeStartDate()}
          onChange={(evt) => routeEndDateUpdate(evt)}
        />
      </InputRow>
      <Error show={error && error !== ""}>{error}</Error>
      <Button
        show={tabSelected === "graph" && !graphSynced && !loading}
        onClick={() => loadWeeks()}
      >
        + Generate Graph
      </Button>
    </Container>
  );
}
const Error = styled.p`
  display: ${({ show }) => (show ? "block" : "none")};
  color: red;
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  margin: 10px 0px 10px 0px;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 0px 0px 10px 0px;

  @media (max-width: 1400px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Container = styled.div`
  margin: 20px 0px 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1400px) {
    margin: 10px 0px 30px 0px;
  }

  @media (max-width: 900px) {
    margin: 10px 0px 10px 0px;
  }
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  margin: 10px 10px 0px 10px;
  padding: 0px;

  @media (max-width: 1400px) {
    margin: 10px 0px 7px 0px;
  }
`;

const LoanSelect = styled.select`
  padding: 7px 25px 7px 10px;
  min-width: 170px;
  margin: none;
  cursor: pointer;
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  outline: none;
  border: none;
  background-color: white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 35px;
`;

const DateInput = styled.input`
  height: 32px;
  /* width: 110px; */
  min-width: 120px;

  @media (max-width: 1400px) {
    width: 160px;
  }
`;

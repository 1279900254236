import React from "react";
import styled from "styled-components";
import StatusIcon from "./StatusIcon";
import Details from "./DMARCDetails";

export default function DMARCRecord(props) {
  const { statusMessage, pass } = props.record;

  return (
    <Container>
      <Header>
        <Title>DMARC Record</Title>
        <Status>
          <StatusIcon pass={pass} />
          <StatusMessage>{statusMessage}</StatusMessage>
        </Status>
      </Header>

      <Text>
        DMARC is a DNS TXT Record that can be published for a domain to control
        what happens if a message fails authentication (i.e. the recipient
        server can't verify that the message's sender is who they say they are).
      </Text>
      <Details record={props.record} />
    </Container>
  );
}

const Text = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.xs};
  margin-top: 0px;
  line-height: 26px;

  @media (max-width: 1200px) {
    font-size: ${({ theme }) => theme.text.xxs};
    line-height: 24px;
  }
`;

const Status = styled.div`
  display: flex;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-top: 10px;
`;

const Title = styled.h1`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.md};
  margin-right: 15px;

  @media (max-width: 800px) {
    margin: 20px 0px 0px 0px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StatusMessage = styled.p`
  font-family: ${({ theme }) => theme.textFont};
  font-size: ${({ theme }) => theme.text.sm};
  margin-left: 10px;

  @media (max-width: 800px) {
    font-size: ${({ theme }) => theme.text.xs};
    margin-left: 7px;
  }
`;

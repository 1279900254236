import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import Charts from "./Charts";
import Chart from "../index";

export default function ChartRouter() {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Charts url={url} />
      </Route>
      <Route path={`${path}/:id`}>
        <Chart />
      </Route>
    </Switch>
  );
}
